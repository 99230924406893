@use './animations.module.scss' as animations;

@include animations.component-appear-animations;

@mixin input-feedback {
  &[data-feedback='info'] {
    @apply outline-feedback-accent;
  }
  &[data-feedback='error'] {
    @apply outline-feedback-alert;
  }
  &[data-feedback='smart'] {
    @apply outline-feedback-smart;
  }
  &[data-feedback='success'] {
    @apply outline-feedback-success;
  }
  &[data-feedback='warning'] {
    @apply outline-feedback-warning;
  }
}

@mixin input-font {
  @apply text-default-regular;

  [data-font='default'] & {
    @apply text-default-regular;
  }
  [data-font='large'] & {
    @apply text-large-regular;
  }
  [data-font='small'] & {
    @apply text-small-regular;
  }
  [data-font='h1'] & {
    @apply text-[1.5rem] leading-10 font-bold;
  }
  [data-font='h2'] & {
    @apply text-[1.25rem] leading-8 font-semibold;
  }
  [data-font='h3'] & {
    @apply text-[1.125rem] leading-6 font-semibold;
  }
}

.root {
  @apply flex flex-1 items-center gap-[6px] w-full bg-input-text-resting rounded-lg px-2 relative isolate;
  @apply border-0 border-none border-current;
  @apply outline outline-input-text-resting outline-1 -outline-offset-1;
  @apply transition ease-in-out motion-reduce:transition-none;
  @apply focus-within:shadow-interactive-focused focus-within:bg-input-text-pressed focus-within:outline-input-text-pressed focus-within:outline-1 focus-within:-outline-offset-1 focus-within:outline;
  @include input-feedback;
  @include input-font;

  &.disabled:not([data-readOnly]):not([data-hoverbadge]) {
    @apply opacity-40 cursor-not-allowed;

    .badge {
      @apply cursor-not-allowed;
    }
  }

  &[data-inline] {
    @apply outline-none -mx-2 bg-transparent focus-within:bg-input-text-resting focus-within:outline focus-within:outline-input-text-resting focus-within:outline-1 focus-within:-outline-offset-1;
    @apply bg-transparent hover:outline hover:outline-input-text-resting hover:outline-1 hover:-outline-offset-1;
  }

  &[data-readOnly] {
    @apply cursor-not-allowed;

    .badge {
      @apply cursor-not-allowed;
    }
  }
}

.input {
  @apply flex-1 w-full py-1 px-0 bg-transparent min-w-0 z-10 relative;
  @apply border-0 border-none border-current;
  @apply text-input-active;
  @apply placeholder:text-input-resting;
  @apply focus:outline-0 focus:border-none focus:shadow-[none] focus:shadow-[0_0_0] focus:border-current;
  @include input-font;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: hsl(var(--text-input-active) / var(--tw-text-opacity)) !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px none;
  }

  &:disabled {
    @apply cursor-not-allowed;
  }
}

.select-trigger {
  @apply w-full min-h-8 px-2 py-1 flex items-center cursor-pointer;
  @apply bg-input-text-resting text-default-body select-none;
  @apply outline outline-1 -outline-offset-1 outline-input-text-resting;
  @apply flex gap-1.5 rounded-lg;
  @apply focus-within:outline-input-text-hovered focus-within:shadow-interactive-focused;

  @include input-feedback;

  &.not-disabled {
    @apply active:bg-input-text-pressed active:outline-input-text-pressed;
    @apply focus-within:outline-input-text-hovered focus-within:shadow-interactive-focused;

    &:not([data-feedback]) {
      @apply hover:outline-input-text-hovered;
    }
  }

  &:not([data-disabled]):not([data-readonly]) {
    @apply active:bg-input-text-pressed active:outline-input-text-pressed;
  }

  &.disabled:not([data-readonly]) {
    @apply opacity-40 cursor-not-allowed;
  }

  &[data-readonly] {
    @apply cursor-not-allowed;
  }
}

.select-popover {
  @apply shadow-depth-03 bg-layer-01 rounded-2xl flex flex-col overflow-hidden;
  @apply border border-outline-01;
  @apply min-w-[var(--radix-dropdown-menu-trigger-width)];

  &[data-side='top'],
  &[data-side='top'] {
    animation: slideUpAndFade 150ms ease-in-out;
  }

  &[data-side='right'],
  &[data-side='right'] {
    animation: slideRightAndFade 150ms ease-in-out;
  }

  &[data-side='bottom'],
  &[data-side='bottom'] {
    animation: slideDownAndFade 150ms ease-in-out;
  }

  &[data-side='left'],
  &[data-side='left'] {
    animation: slideLeftAndFade 150ms ease-in-out;
  }
}

.select-scroll-area {
  @apply max-h-60 flex flex-col flex-1;
}

.select-value {
  @apply flex-1 max-w-full overflow-hidden;

  &[data-selected='true'] {
    @apply text-input-active;
  }
  &[data-selected='false'] {
    @apply text-input-resting;
  }

  span:not(:last-child)::after {
    content: ', ';
  }
}

.select-icon {
  @apply text-input-resting;
}

.count {
  @apply text-color-muted text-mini-regular;
  @apply flex items-center gap-px;
}

.increase-button,
.decrease-button {
  @apply justify-center w-4 flex text-color-muted;

  &[data-disabled='false']:hover {
    @apply text-color-tertiary;
  }
}
