.grid {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: "spotlight spotlight spotlight spotlight" "start headerContent tabs actions"  "content content content content" "footer footer footer footer";
}

@container sidePanel (max-width: 560px) {
    .grid {
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto auto auto 1fr auto;
        grid-template-areas: "spotlight spotlight spotlight" "start headerContent actions" "tabs tabs tabs"  "content content content" "footer footer footer";
    
    }
}