.radio {
  &:not([data-disabled]) {
    &[data-state="checked"] {
      @apply outline-input-select-active;

      span {
        @apply opacity-100;

        [data-state="checked"] {
          @apply block outline-input-select-active border-input-select-active border-4 rounded-full w-4 h-4;
        }
      }
    }
    span {
      @apply opacity-0 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2;
    }
  }
  &:disabled {
    @apply outline-input-select-disabled bg-input-select-disabled cursor-not-allowed opacity-40;
    &[data-state="checked"] {
      @apply opacity-40 outline-input-select-active bg-transparent;

      span {
        @apply opacity-100;

        [data-state="checked"] {
          @apply block outline-input-select-active border-input-select-active border-4 rounded-full w-4 h-4;
        }
      }
    }
  }
  & [data-disabled] {
    @apply hidden;
  }
}
.label-wrapper {
  &[data-disabled="true"] {
    @apply cursor-not-allowed opacity-40;
  }
}
