.loader {
  @apply space-x-0.5;

  @keyframes cycle-opacity {
    0%,
    100% {
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }
  }

  .dot:nth-child(1) {
    animation: cycle-opacity 1.5s ease-in-out infinite;
  }
  .dot:nth-child(2) {
    animation: cycle-opacity 1.5s ease-in-out 0.3s infinite;
  }
  .dot:nth-child(3) {
    animation: cycle-opacity 1.5s ease-in-out 0.6s infinite;
  }
}

.dot {
  @apply bg-dotted-loader;
  @apply rounded-full;
  opacity: 0;

  &.base {
    width: 3px;
    height: 3px;
  }

  &.sm {
    width: 2px;
    height: 2px;
  }
}
