:root {
  --accent-h: 237; /* primary color */
  --accent-s: 81%;
  --accent-l: 56%;

  --accent-label-h: 0; /* primary button text color */
  --accent-label-s: 100%;
  --accent-label-l: 100%;

  --neutral-h: 228; /* Background color */
  --neutral-s: 10%;

  --alert-h: 360;
  --alert-s: 72%;
  --alert-l: 52%;

  --warning-h: 47;
  --warning-s: 74%;
  --warning-l: 55%;

  --success-h: 135;
  --success-s: 55%;
  --success-l: 55%;

  --smart-h: 256;
  --smart-s: 94%;
  --smart-l: 63%;

  --fontSize-h1: 1.75rem;
  --lineHeight-h1: 2.25rem;
  --fontWeight-h1-medium: 500;

  --fontSize-h2: 1.5rem;
  --lineHeight-h2: 2rem;
  --fontWeight-h2-medium: 500;

  --fontSize-h3: 1.25rem;
  --lineHeight-h3: 1.75rem;
  --fontWeight-h3-medium: 500;

  --fontSize-h4: 1.125rem;
  --lineHeight-h4: 1.5rem;
  --fontWeight-h4-medium: 500;

  --fontSize-h5: 1rem;
  --lineHeight-h5: 1.375rem;
  --fontWeight-h5-medium: 500;

  --fontSize-h6: 0.875rem;
  --lineHeight-h6: 1.25rem;
  --fontWeight-h6-medium: 500;

  --fontSize-large: 1rem;
  --lineHeight-large: 1.375rem;
  --fontWeight-large-regular: 400;

  --fontSize-default: 0.875rem;
  --lineHeight-default: 1.125rem;
  --fontWeight-default-regular: 400;
  --fontWeight-default-medium: 500;

  --fontSize-small: 0.75rem;
  --lineHeight-small: 1.125rem;
  --fontWeight-small-regular: 400;
  --fontWeight-small-medium: 500;

  --fontSize-mini: 0.6875rem;
  --lineHeight-mini: 1rem;
  --fontWeight-mini-regular: 400;
  --fontWeight-mini-medium: 500;

  @media only screen and (min-width: 768px) {
    --fontSize-h1: 2.25rem;
    --lineHeight-h1: 2.625rem;

    --fontSize-h2: 1.75rem;
    --lineHeight-h2: 2.25rem;

    --fontSize-h3: 1.5rem;
    --lineHeight-h3: 2rem;

    --fontSize-h4: 1.125rem;
    --lineHeight-h4: 1.5rem;

    --fontSize-h5: 1rem;
    --lineHeight-h5: 1.375rem;

    --fontSize-h6: 0.875rem;
    --lineHeight-h6: 1.25rem;

    --fontSize-large: 1rem;
    --lineHeight-large: 1.5rem;

    --fontSize-default: 0.875rem;
    --lineHeight-default: 1.5rem;

    --fontSize-small: 0.75rem;
    --lineHeight-small: 1rem;

    --fontSize-mini: 0.75rem;
    --lineHeight-mini: 1rem;
  }
}
