@use './animations.module.scss' as animations;

@include animations.component-appear-animations;

.select-popover {
  @apply shadow-depth-03 bg-layer-01 rounded-lg z-menu;
  @apply outline outline-1 -outline-offset-1 outline-outline-01;
  @apply w-[264px] overflow-hidden items-start;

  &[data-side='top'],
  &[data-side='top'] {
    animation: slideUpAndFade 150ms ease-in-out;
  }

  &[data-side='right'],
  &[data-side='right'] {
    animation: slideRightAndFade 150ms ease-in-out;
  }

  &[data-side='bottom'],
  &[data-side='bottom'] {
    animation: slideDownAndFade 150ms ease-in-out;
  }

  &[data-side='left'],
  &[data-side='left'] {
    animation: slideLeftAndFade 150ms ease-in-out;
  }
}

.picker-inputs {
  @apply flex justify-center overflow-hidden;

  span {
    @apply hidden;
  }
}

.no-alpha {
  div:nth-child(4) {
    @apply opacity-40;
    input {
      @apply cursor-not-allowed;
    }
  }
}

.swatches {
  @apply gap-2;

  div {
    @apply -outline-offset-[3px] shadow-lg;

    width: 24px !important;
    height: 24px !important;
    margin: 0 !important;
    outline: solid 4px white !important;
    border-radius: 6px !important;
  }
}

.alpha-slider[data-alpha='true'] div div {
  @apply rotate-180;
}
