.root {
  transition: flex-grow 200ms ease-in-out, gap 200ms ease-in-out; // Overall width animation duration

  span:not(:first-of-type) {
    @apply inline-block duration-200 overflow-hidden; // Label animation duration
  }

  &[data-state="inactive"] {
    @apply gap-0;

    span:not(:first-of-type) {
      @apply opacity-0 max-w-0 invisible;
    }
  }

  &[data-state="active"] {
    @apply gap-1;

    @apply bg-transparent text-interactive-tab-pressed grow-[2];

    span:not(:first-of-type) {
      @apply opacity-100 max-w-[75px] visible;
    }
  }
}
