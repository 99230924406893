.content {
  [role='listitem'] {
    @apply h-8 opacity-100 visible;
    transition: height 100ms ease-in-out, opacity 150ms ease-in-out, visibility 0ms ease-in-out;
  }

  &[data-state='open'] {
    @apply motion-reduce:animate-none;
  }

  &[data-state='closed'] {
    @apply motion-reduce:animate-none;

    [role='listitem'] {
      &[data-status='inactive'] {
        @apply h-0 opacity-0 collapse;
        transition: height 100ms ease-in-out 50ms, opacity 150ms ease-in-out, visibility 150ms ease-in-out 150ms;
      }
    }
  }
}

.header {
  [data-nav-state='collapsed'] &{
    @apply bg-layer-01;
  }
}
