.editor-content--experience-chat {
  @apply leading-[1.375rem];

  & > * {
    @apply mb-0;
  }

  h1,
  h2,
  p {
    @apply mb-0;
  }
  li > p {
    @apply mb-0;
  }

  hr,
  blockquote,
  pre {
    @apply my-1.5;
  }

  *:first-child {
    @apply mt-0;
  }

  *:last-child {
    @apply mb-0;
  }
}

.editor-content--experience-document {
  @apply leading-[1.375rem];

  & > * {
    @apply mb-1.5;
  }

  h1,
  h2,
  p {
    @apply mb-3;
  }
  li > p {
    @apply mb-0;
  }

  *:last-child {
    @apply mb-0;
  }
}

.editor-content--experience-email {
  @apply leading-[1.375rem];

  & > * {
    @apply mb-1.5;
  }

  h1,
  h2,
  p {
    @apply mb-3;
  }
  li > p {
    @apply mb-0;
  }

  *:last-child {
    @apply mb-0;
  }
}

.editor-content {
  @apply border-0 text-color-primary text-default;

  ul,
  ol {
    @apply ml-4;
  }

  li {
    @apply ml-2;
  }

  ul {
    list-style-type: disc;
  }
  ul ul {
    list-style-type: circle;
  }
  ul ul ul {
    list-style-type: square;
  }

  ol {
    list-style-type: decimal;
  }
  ol ol {
    list-style-type: lower-latin;
  }
  ol ol ol {
    list-style-type: lower-roman;
  }

  /* RTL support for lists */
  ol:has(p:dir(rtl)),
  ul:has(p:dir(rtl)) {
    direction: rtl;
    @apply pr-4 pl-0 ml-0 mr-0;

    > li {
      direction: rtl;
      margin-left: 0;
      margin-right: 0;
      list-style-position: inside;

      > p {
        display: inline-block;
      }
    }
  }

  code {
    @apply py-0.5 rounded text-small-medium;
  }
  :not(pre) > code {
    @apply px-1 bg-feedback-neutral/10 selection:bg-decorative-green/10 text-decorative-green;
  }
  pre {
    @apply bg-layer-02 rounded-md outline outline-1 outline-outline-02 -outline-offset-1 p-2 text-default-regular;
    > code {
      @apply bg-transparent selection:bg-feedback-neutral/10 py-0.5 rounded text-feedback-neutral;
      white-space: pre-wrap;
    }
  }

  pre span {
    @apply whitespace-pre-wrap;
  }
  blockquote {
    @apply border-l-4 pl-3 border-separator;
  }

  a {
    @apply text-color-link hover:underline cursor-pointer break-words;
  }

  hr {
    @apply border-input-text-pressed;
  }

  :global(p.is-editor-empty:first-child::before) {
    @apply text-input-resting;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  :global(p.is-empty-article::before) {
    @apply text-input-resting;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  table {
    @apply border-collapse border-spacing-0 text-color-primary text-default-regular box-border w-full p-4;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    td,
    th {
      @apply relative align-top box-border min-w-[128px] p-2 text-left border border-outline-01;

      > * {
        @apply mb-0;
      }
    }

    :global(.selectedCell) {
      @apply bg-layer-00;
    }

    :global(.grip-column),
    :global(.grip-row) {
      @apply items-center cursor-pointer flex justify-center absolute z-10 border-interactive-secondary-hovered bg-interactive-secondary-hovered;

      &:hover,
      &.selected {
        @apply bg-interactive-secondary-pressed border-interactive-secondary-pressed;
      }

      &.first {
        @apply border-transparent;
      }
    }

    :global(.grip-column) {
      @apply w-[calc(100%+1px)] border-l h-3 left-0 -ml-[1px] -top-3;

      &:hover,
      &.selected {
        &::before {
          content: '';
          @apply w-2.5 border-b-2 border-dotted border-feedback-inverted;
        }
      }
    }

    :global(.grip-row) {
      @apply h-[calc(100%+1px)] border-t -left-3 w-3 top-0 -mt-[1px];

      &:hover,
      &.selected {
        &::before {
          content: '';
          @apply h-2.5 border-l-2 border-dotted border-feedback-inverted;
        }
      }
    }
  }

  table tr th,
  table tr td {
    @apply border-r border-b border-outline-01 px-3 py-1 text-left;
  }

  table tr th:first-child,
  table tr td:first-child {
    @apply border-l border-outline-01;
  }
  table tr th {
    @apply bg-layer-00 border-t border-outline-01 text-color-tertiary text-small-regular;
  }

  &:global(.resize-cursor) {
    cursor: ew-resize;
    cursor: col-resize;
  }

  :global(.column-resize-handle) {
    @apply absolute w-1 pointer-events-none bottom-[-1px] right-[-1px] top-[-1px] bg-feedback-accent;
  }

  :global(.ProseMirror-selectednode) {
    @apply outline outline-2 outline-feedback-accent/20 rounded-sm;
  }

  ul[data-type='taskList'] {
    /* Removes default list bullet points */
    @apply list-none;

    p {
      @apply m-0;
    }

    li {
      @apply flex;

      > label {
        @apply flex-grow-0 flex-shrink-0 basis-auto mr-2 select-none;
      }

      > div {
        @apply flex-auto;
      }

      ul li,
      ol li {
        /* Defines list items within nested lists */
        @apply list-item;
      }

      ul[data-type='taskList'] > li {
        @apply flex;
      }
    }
  }

  // Strange hack to remove the separator image in cells
  td :global(.node-objectId) + :global(img.ProseMirror-separator) {
    width: 0px !important;
  }

  // css for code blocks downloaded from https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/github-dark.min.css
  :global {
    pre code.hljs {
      display: block;
      overflow-x: auto;
      padding: 1em;
    }

    code.hljs {
      padding: 3px 5px;
    }

    .hljs {
      color: #c9d1d9;
      background: #0d1117;
    }

    .hljs-comment {
      color: #5c6370;
      font-style: italic;
    }

    .hljs-quote {
      color: #5c6370;
      font-style: italic;
    }

    .hljs-doctag,
    .hljs-keyword,
    .hljs-formula {
      color: #c678dd;
    }

    .hljs-section,
    .hljs-name,
    .hljs-selector-tag,
    .hljs-deletion,
    .hljs-subst {
      color: #e06c75;
    }

    .hljs-literal {
      color: #56b6c2;
    }

    .hljs-string,
    .hljs-regexp,
    .hljs-addition,
    .hljs-attribute,
    .hljs-meta-string {
      color: #98c379;
    }

    .hljs-built_in,
    .hljs-class .hljs-title {
      color: #e6c07b;
    }

    .hljs-attr,
    .hljs-variable,
    .hljs-template-variable,
    .hljs-type,
    .hljs-selector-class,
    .hljs-selector-attr,
    .hljs-selector-pseudo,
    .hljs-number {
      color: #d19a66;
    }

    .hljs-symbol,
    .hljs-bullet,
    .hljs-link,
    .hljs-meta,
    .hljs-selector-id,
    .hljs-title {
      color: #61aeee;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: bold;
    }

    .hljs-link {
      text-decoration: underline;
    }
  }
}

.is-safari {
  &:global(.line-clamp-1) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;

    p {
      display: inline;
    }

    p::after {
      content: ' \A\A';
      white-space: pre;
    }
  }
}

:global(.light) {
  .editor-content {
    :global {
      .hljs {
        color: #383a42;
        background: #fafafa;
      }

      .hljs-comment,
      .hljs-quote {
        color: #a0a1a7;
        font-style: italic;
      }

      .hljs-doctag,
      .hljs-keyword,
      .hljs-formula {
        color: #a626a4;
      }

      .hljs-section,
      .hljs-name,
      .hljs-selector-tag,
      .hljs-deletion,
      .hljs-subst {
        color: #e45649;
      }

      .hljs-literal {
        color: #0184bb;
      }

      .hljs-string,
      .hljs-regexp,
      .hljs-addition,
      .hljs-attribute,
      .hljs-meta-string {
        color: #50a14f;
      }

      .hljs-built_in,
      .hljs-class .hljs-title {
        color: #c18401;
      }

      .hljs-attr,
      .hljs-variable,
      .hljs-template-variable,
      .hljs-type,
      .hljs-selector-class,
      .hljs-selector-attr,
      .hljs-selector-pseudo,
      .hljs-number {
        color: #986801;
      }

      .hljs-symbol,
      .hljs-bullet,
      .hljs-link,
      .hljs-meta,
      .hljs-selector-id,
      .hljs-title {
        color: #4078f2;
      }

      .hljs-emphasis {
        font-style: italic;
      }

      .hljs-strong {
        font-weight: bold;
      }

      .hljs-link {
        text-decoration: underline;
      }
    }
  }
}
