.prev-month-button, .next-month-button {
  @apply w-6 h-6;

  i {
    @apply w-2;

    path {
      @apply stroke-[1.5] text-interactive-tertiary-resting;
    }
  }
}

.select {
  &[data-state="open"] {
    @apply bg-interactive-tertiary-pressed;
  }

  div[data-selected="true"] {
    @apply text-interactive-tertiary-resting text-default-medium;
  }

  i {
    @apply w-2;

    path {
      @apply stroke-[1.5] text-interactive-tertiary-resting;
    }
  }
}

.currentMonthButton {
  @apply w-6 h-6;

  i {
    @apply w-2.5;

    path {
      @apply stroke-[1.5] text-interactive-tertiary-resting;
    }
  }
}
