.trigger {
  @apply h-6 pl-0 outline-[none] bg-[transparent] duration-0;
  @apply hover:bg-transparent hover:outline-[0];
}

.selectOption {
  & > div {
    @apply flex items-center;

    [data-role="country-flag"] {
      @apply w-[22px] h-[22px] mr-1.5;
    }

    i {
      @apply w-5 mr-1.5 pl-[1px];
      @apply text-color-muted;
    }
  }
}
