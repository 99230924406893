.root {
  @apply duration-300 ease-in-out motion-reduce:transition-none;
  &[data-state='open'] {
    animation: slideInFromRight 150ms ease-out;
  }

  &[data-state='closed'] {
    animation: slideOutToLeft 150ms ease-out;
  }
}

.overlay {
  animation: overlayShow 150ms ease-out;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
