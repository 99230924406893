@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}
@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    opacity: 1;
    height: var(--radix-accordion-content-height);
  }
}

.grid {
  grid-template-columns: 1fr; 
}

.trigger {
  &[data-state='open'] {
    i {
      @apply rotate-180;
    }
  }
}

.icon {
  i {
    @apply duration-300;
  }
}

.content {
  &[data-state='open'] {
    animation: slideDown 100ms ease-in-out;
  }
  &[data-state='closed'] {
    animation: slideUp 100ms ease-in-out;
  }
}
