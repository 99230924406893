@mixin textarea-font {
  @apply text-default-regular min-h-[65px];

  [data-font='default'] & {
    @apply text-default-regular min-h-[65px];
  }
  [data-font='large'] & {
    @apply text-large-regular min-h-[65px];
  }
  [data-font='small'] & {
    @apply text-small-regular min-h-[65px];
  }
  [data-font='h1'] & {
    @apply text-[1.5rem] leading-10 font-semibold min-h-0;
  }
  [data-font='h2'] & {
    @apply text-[1.25rem] leading-8 font-semibold min-h-0;
  }
  [data-font='h3'] & {
    @apply text-[1.125rem] leading-6 font-semibold min-h-0;
  }
}

@mixin textarea-feedback {
  &[data-feedback='info'] {
    @apply outline-feedback-accent;
  }
  &[data-feedback='error'] {
    @apply outline-feedback-alert;
  }
  &[data-feedback='smart'] {
    @apply outline-feedback-smart;
  }
  &[data-feedback='success'] {
    @apply outline-feedback-success;
  }
  &[data-feedback='warning'] {
    @apply outline-feedback-warning;
  }
}

.root {
  @apply relative flex flex-row items-start justify-start gap-[6px] w-full h-full bg-input-text-resting rounded-lg pl-3 pt-2 overflow-hidden;
  @apply border-0 border-none border-current;
  @apply outline outline-1 -outline-offset-1;
  @apply transition ease-in-out motion-reduce:transition-none;
  @apply focus-within:shadow-interactive-focused focus-within:bg-input-text-pressed focus-within:outline-input-text-pressed focus-within:outline-1 focus-within:-outline-offset-1 focus-within:outline;

  &[data-inline] {
    @apply outline-none p-0 focus-within:outline-0 focus-within:border-none focus-within:shadow-[none] focus-within:shadow-[0_0_0] focus:border-current ;
    @apply bg-transparent rounded-none;
  }
  @include textarea-feedback;
}

.textarea {
  @apply w-full h-full p-0 bg-transparent pr-[34px] pb-2;
  @apply border-0 border-none border-current;
  @apply text-input-active;
  @apply placeholder:text-input-resting;
  @apply focus:outline-0 focus:border-none focus:shadow-[none] focus:shadow-[0_0_0] focus:border-current;
  position: relative;
  z-index: 1;
  vertical-align: top;
  @include textarea-font;

  [data-inline] & {
    @apply min-h-0 p-0;
  }

  &:disabled {
    @apply cursor-not-allowed;
  }
}

.textarea-suggestion {
  @apply w-full h-12 p-0 bg-transparent pr-[34px] pb-2;
  @apply border-0 border-none border-current;
  @apply placeholder:text-input-resting;
  @apply focus:outline-0 focus:border-none focus:shadow-[none] focus:shadow-[0_0_0] focus:border-current;
  @apply text-input-resting text-default-regular;
  @apply bg-transparent bg-none;
  @apply absolute;

  @include textarea-font;

  [data-inline] & {
    @apply min-h-0 p-0;
  }
}

.textarea::-webkit-resizer {
  color: transparent;
  background-color: transparent;
}

.textarea::-webkit-scrollbar-corner {
  background-color: transparent;
}

.textarea-wrapper {
  @apply bg-transparent flex-1 h-full relative;
  display: inline-block;
}

.textarea-icon {
  @apply bg-input-text-resting;
  color: #E9E9EC;
  border-bottom-right-radius: 8px;
  height: 15px;
  width: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
}

.textarea-icon-false {
  color: transparent;
  height: 0px;
  width: 0px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  pointer-events: none;
  z-index: 2;
}
