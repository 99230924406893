@container aside-container (min-width : 560px) {
  .asideScrollAreaWrapper {
    @apply shadow-depth-02;
  }
}

.resizeHandlerWrapper {
  &:hover {
    .resizeHandler {
      @apply bg-feedback-accent transition-opacity opacity-60 w-0.5;
    }

    i {
      @apply opacity-0;
    }

    &:hover {
      i {
        @apply opacity-100 transition-opacity;
      }
    }
  }

  [data-aside='close'] & {
    @apply hidden;
  }
  [data-aside='open'] & {
    @apply flex;
  }
}
.showIconOnResize {
  i {
    @apply opacity-100;
  }
}

.aside {
  [data-aside='close'] & {
    @apply hidden;
  }
  [data-aside='open'] & {
    @apply block;
  }
  [data-aside='overlay'] & {
    @apply block;
    animation: asideOpen 0.3s ease-in-out;
  }
}

@keyframes asideOpen {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes asideClose {
  from {
    display: block;
    transform: translateX(-100%);
  }
  to {
    display: none;
    transform: translateX(0);
  }
}

.viewSeperator {
  display: block;
}

.viewTabList {
  @apply px-0;
}

@container sidePanel (max-width : 560px) {
  .viewSeperator {
    @apply hidden;
  }
  .viewTabList {
    @apply px-3;
  }
}

/**
* scroll area internal style display : table were causing overflow issues 
* so we are overriding above display properties 
**/
.scrollAreaViewPortHack {
  [data-radix-scroll-area-viewport] > div {
    display: block !important;
  }
}
