.root {
  >:first-child {
    @apply duration-100;
    transform: translateX(-100%) translateX(14px);
  }

  &[data-state="checked"] {
    @apply bg-input-select-active outline-input-select-active;

    >:first-child {
      @apply translate-x-[14px];
    }

    >:nth-child(2) {
      @apply opacity-100;
    }
  }
}


.icon {
  @apply absolute opacity-0 left-1/4 top-1/2 transform -translate-x-1/2 -translate-y-1/2;

  i {
    @apply w-2 h-2;
  }
}
