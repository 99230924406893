@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(-1px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    transform: translateX(1px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(1px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    transform: translateX(-1px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.root {
  &[data-state='delayed-open'][data-side='bottom'] {
    animation: slideUpAndFade 150ms ease-out;
  }
  &[data-state='delayed-open'][data-side='left'] {
    animation: slideRightAndFade 150ms ease-out;
  }
  &[data-state='delayed-open'][data-side='top'] {
    animation: slideDownAndFade 150ms ease-out;
  }
  &[data-state='delayed-open'][data-side='right'] {
    animation: slideLeftAndFade 150ms ease-out;
  }
}

.arrow {
  &[data-direction="top"] {
    top: -1px;
  }
  &[data-direction="bottom"] {
    bottom: 1px;
  }
  &[data-direction="right"] {
    bottom: 1px;
  }
  &[data-direction="left"] {
    bottom: 1px;
  }
}
