@import 'core-styles.css';

:root.light,
.force-light {
  --secondary-h: 228; /* secondary color */
  --secondary-s: 10%;
  --secondary-l: 100%;

  --surface-h: 60; /* surface/body color */
  --surface-s: 40%;
  --surface-l: 98%;

  /* Background */
  --bg-layer-00: var(--neutral-980);
  --bg-layer-01: var(--surface-h) var(--surface-s) var(--surface-l);
  --bg-layer-02: var(--neutral-1000);
  --bg-layer-03: var(--neutral-1000);
  --bg-layer-04: var(--neutral-1000);

  --bg-inverted: var(--neutral-100);

  --bg-outline-00: var(--neutral-920);
  --bg-outline-01: var(--neutral-940);
  --bg-outline-02: var(--neutral-940);
  --bg-outline-03: var(--neutral-940);

  --bg-interactive-primary-resting: var(--accent-500);
  --bg-interactive-primary-hovered: var(--accent-400);
  --bg-interactive-primary-pressed: var(--accent-300);

  --bg-interactive-secondary-resting: var(--secondary-500);
  --bg-interactive-secondary-hovered: var(--secondary-400);
  --bg-interactive-secondary-pressed: var(--secondary-300);

  --bg-interactive-tertiary-hovered: var(--neutral-960);
  --bg-interactive-tertiary-pressed: var(--neutral-940);

  --bg-interactive-destructive-resting: var(--alert-500);
  --bg-interactive-destructive-hovered: var(--alert-400);
  --bg-interactive-destructive-pressed: var(--alert-300);

  --bg-interactive-smart-resting: var(--smart-500);
  --bg-interactive-smart-hovered: var(--smart-400);
  --bg-interactive-smart-pressed: var(--smart-300);

  --bg-interactive-navigation-hovered: var(--neutral-940);
  --bg-interactive-navigation-pressed: var(--accent-500);

  --bg-interactive-bucket-resting: var(--neutral-980);
  --bg-interactive-bucket-hovered: var(--neutral-940);
  --bg-interactive-bucket-pressed: var(--neutral-1000);

  --bg-interactive-stack-pressed: var(--neutral-940);
  --bg-interactive-stack-resting: var(--neutral-980);
  --bg-interactive-stack-hovered: var(--neutral-960);

  --bg-interactive-tab-hovered: var(--neutral-940);
  --bg-interactive-tab-pressed: var(--neutral-1000);

  --bg-input-text-resting: var(--neutral-980);
  --bg-input-text-pressed: var(--neutral-1000);

  --bg-input-search-resting: var(--neutral-1000);
  --bg-input-search-pressed: var(--neutral-980);

  --bg-input-select-active: var(--accent-500);
  --bg-input-select-pressed: var(--accent-400);
  --bg-input-select-disabled: var(--neutral-780);
  --bg-input-select-resting: var(--neutral-860);
  --bg-input-select-hovered: var(--neutral-820);

  /* Border */
  --border-outline-00: var(--neutral-920);
  --border-outline-01: var(--neutral-940);
  --border-outline-02: var(--neutral-940);
  --border-outline-03: var(--neutral-940);

  --border-outline-accent: var(--accent-500);

  --border-interactive-primary-resting: var(--accent-400);
  --border-interactive-primary-hovered: var(--accent-300);
  --border-interactive-primary-pressed: var(--accent-200);

  --border-interactive-secondary-resting: var(--neutral-960);
  --border-interactive-secondary-hovered: var(--neutral-920);
  --border-interactive-secondary-pressed: var(--neutral-900);

  --border-interactive-tertiary-hovered: var(--neutral-960);
  --border-interactive-tertiary-pressed: var(--neutral-920);

  --border-interactive-destructive-resting: var(--alert-400);
  --border-interactive-destructive-hovered: var(--alert-300);
  --border-interactive-destructive-pressed: var(--alert-200);

  --border-interactive-smart-resting: var(--smart-400);
  --border-interactive-smart-hovered: var(--smart-300);
  --border-interactive-smart-pressed: var(--smart-200);

  --border-interactive-navigation-hovered: var(--neutral-880);
  --border-interactive-navigation-pressed: var(--accent-500);

  --border-interactive-bucket-resting: var(--neutral-940);
  --border-interactive-bucket-hovered: var(--neutral-900);
  --border-interactive-bucket-pressed: var(--neutral-960);

  --border-interactive-tab-hovered: var(--neutral-900);
  --border-interactive-tab-pressed: var(--neutral-960);

  --border-input-text-resting: var(--neutral-920);
  --border-input-text-hovered: var(--neutral-880);
  --border-input-text-pressed: var(--neutral-880);

  --border-input-search-resting: var(--neutral-960);
  --border-input-search-hovered: var(--neutral-920);
  --border-input-search-pressed: var(--neutral-880);

  --border-input-select-resting: var(--neutral-860);
  --border-input-select-hovered: var(--neutral-820);
  --border-input-select-active: var(--accent-400);
  --border-input-select-pressed: var(--accent-300);
  --border-input-select-disabled: var(--neutral-900);

  /* text */
  --text-color-highlight: var(--neutral-80);
  --text-color-primary: var(--neutral-120);
  --text-color-secondary: var(--neutral-360);
  --text-color-tertiary: var(--neutral-480);
  --text-color-muted: var(--neutral-620);

  --text-color-accent: var(--accent-500);

  --text-interactive-accent-resting: var(--accent-500);
  --text-interactive-accent-hovered: var(--accent-400);
  --text-interactive-accent-active: var(--accent-300);

  --text-color-inverted: var(--neutral-1000);

  --text-static-white: var(--neutral-1000);
  --text-static-black: var(--neutral-80);

  --text-interactive-primary-resting: var(--accent-label-h) var(--accent-label-s) var(--accent-label-l);

  --text-interactive-secondary-resting: var(--text-color-primary);

  --text-interactive-tertiary-resting: var(--text-color-secondary);
  --text-interactive-tertiary-hovered: var(--text-color-primary);
  --text-interactive-tertiary-active: var(--text-color-highlight);

  --text-interactive-navigation-resting: var(--text-color-secondary);
  --text-interactive-navigation-pressed: var(--text-color-accent);

  --text-interactive-bucket-resting: var(--text-color-secondary);
  --text-interactive-bucket-pressed: var(--text-color-primary);

  --text-interactive-link-resting: var(--blue-500);
  --text-interactive-link-hovered: var(--blue-450);

  --text-interactive-tab-hovered: var(--neutral-360);
  --text-interactive-tab-resting: var(--neutral-360);
  --text-interactive-tab-pressed: var(--text-color-primary);

  --text-input-resting: var(--text-color-muted);
  --text-input-active: var(--text-color-primary);

  /* feedback */
  --color-feedback-alert: var(--alert-400);
  --color-feedback-warning: var(--warning-400);
  --color-feedback-success: var(--success-400);
  --color-feedback-smart: var(--smart-400);
  --color-feedback-inverted: var(--neutral-80);
  --color-feedback-accent: var(--accent-600);
  --color-feedback-neutral: var(--neutral-480);

  /* object */
  --color-object-developer: var(--blue-600);
  --color-object-customer: var(--green-600);
  --color-object-mention: var(--orange-600);
  --color-object-engagement: var(--pink-500);
  --color-object-opportunity: var(--lime-500);
  --color-object-conversation: var(--orange-500);
  --color-object-ticket: var(--color-feedback-neutral);
  --color-object-issue: var(--blue-500);
  --color-object-sub-feature: var(--sky-500);
  --color-object-database: var(--orange-500);
  --color-object-linkable: var(--yellow-500);
  --color-object-runnable: var(--lime-500);
  --color-object-api-cluster: var(--yellow-500);
  --color-object-api: var(--green-500);
  --color-object-product: var(--violet-500);
  --color-object-enhancement: var(--green-500);
  --color-object-feature: var(--sky-500);
  --color-object-capability: var(--blue-500);
  --color-object-sprint-board: var(--lime-500);
  --color-object-incident: var(--red-500);

  --color-transparent-opacity: var(--neutral-80);

  --color-transparent-opacity: var(--neutral-80);

  --color-skeleton: var(--neutral-780);
  --color-skeleton-light: var(--neutral-1000);
  --color-skeleton-dark: var(--neutral-780);

  --color-decorative-red: var(--red-400);
  --color-decorative-orange: var(--orange-550);
  --color-decorative-yellow: var(--yellow-600);
  --color-decorative-lime: var(--lime-700);
  --color-decorative-green: var(--green-400);
  --color-decorative-sky: var(--sky-600);
  --color-decorative-blue: var(--blue-600);
  --color-decorative-violet: var(--violet-550);
  --color-decorative-pink: var(--pink-650);

  /* smart loading bar */
  /* TODO @pranav - change these hardcoded values when we have correct token values */
  --bg-smart-loading: 250 80% 64%;
  --gradient-smart-loading: linear-gradient(
    to right,
    hsla(249, 84%, 93%, 0),
    hsla(249, 84%, 93%, 1),
    hsla(249, 84%, 93%, 0)
  );
  --gradient-smart-loading-reverse: linear-gradient(
    to right,
    hsla(249, 84%, 93%, 1),
    hsla(250, 80%, 64%, 1) 15%,
    90%,
    hsla(249, 84%, 93%, 1) 100%
  );

  /* shadows */
  --shadow-depth-04: 0 4px 12px 0 hsla(var(--neutral-80) / 0.1), 0 32px 80px 0 hsla(var(--neutral-80) / 0.2);
  --shadow-depth-03: 0 0 6px 1px hsla(var(--neutral-80) / 0.05), 0 2px 24px 0 hsla(var(--neutral-80) / 0.08);
  --shadow-depth-02: 0 2px 12px 0 hsla(var(--neutral-80) / 0.12), 0 0 6px 0 hsla(var(--neutral-80) / 0.05);
  --shadow-depth-01: 0 0 2px 0 hsla(var(--neutral-80) / 0.1), 0 2px 4px 0 hsla(var(--neutral-80) / 0.05);
  --shadow-popover-arrow: 0px 2px 0px hsla(var(--border-outline-01) / 1);
  --shadow-interactive-focused: 0px 0px 0px 4px hsl(var(--accent-600) / 0.1),
    0px 0px 0px 1px hsl(var(--accent-600) / 0.4);
  --shadow-interactive-lifted: 0 2px 8px 0 hsl(var(--neutral-100) / 0.08);
  --shadow-interactive-pressed: inset 0 2px 0 0 hsl(var(--neutral-120) / 0.05);
  --shadow-interactive-resting: 0 1px 2px 0 hsl(var(--neutral-120) / 0.05);
  --shadow-depth-inverted: -0.01px -0.12px 3px 0px rgba(0, 0, 0, 0.02), -0.02px -0.6px 7px 0px rgba(0, 0, 0, 0.03),
    -0.06px -1.4px 14px 0px rgba(0, 0, 0, 0.04), -0.11px -2.5px 27px 0px rgba(0, 0, 0, 0.04),
    -0.18px -4.5px 50px 0px rgba(0, 0, 0, 0.05), -0.28px -7px 87px 0px rgba(0, 0, 0, 0.07);
  --shadow-effect-2d: 0px -1px 0px 0px hsl(var(--neutral-120) / 0.05) inset;
}
