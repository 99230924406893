@mixin feedback-border {
  &[data-feedback="info"] {
    @apply border-feedback-accent;
  }
  &[data-feedback="error"] {
    @apply border-feedback-alert;
  }
  &[data-feedback="smart"] {
    @apply border-feedback-smart;
  }
  &[data-feedback="success"] {
    @apply border-feedback-success;
  }
  &[data-feedback="warning"] {
    @apply border-feedback-warning;
  }
};

@mixin feedback-icon {
  &[data-feedback="info"] {
    @apply text-feedback-accent;
  }
  &[data-feedback="error"] {
    @apply text-feedback-alert;
  }
  &[data-feedback="smart"] {
    @apply text-feedback-smart;
  }
  &[data-feedback="success"] {
    @apply text-feedback-success;
  }
  &[data-feedback="warning"] {
    @apply text-feedback-warning;
  }
}

.root {
  @include feedback-border;
}

.close {
  @apply absolute -top-4 -right-4;
  @apply w-8 h-8 rounded-full items-center justify-center;
  @apply shadow-interactive-resting;
  @apply bg-interactive-secondary-resting;
  @apply outline outline-1 -outline-offset-1 outline-interactive-secondary-resting;

  i {
    @apply text-interactive-secondary-resting w-3;
  }
}

.start, .end {
  @include feedback-icon;
  i {
    @apply w-6 h-6;
  }
}
