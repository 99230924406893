.root {
  @apply flex items-center justify-between w-full p-2 text-interactive-secondary-resting text-default-regular bg-interactive-secondary-resting shadow-none outline-none text-left rounded-[inherit];
  @apply hover:bg-interactive-secondary-hovered;
  @apply active:bg-interactive-secondary-pressed;
  @apply disabled:cursor-not-allowed disabled:bg-interactive-secondary-resting;
  @apply focus-within:outline focus-within:-outline-offset-2 focus-within:z-10 focus-within:outline-interactive-secondary-hovered;

  &[data-state='open'] {
    .icon i {
      @apply rotate-90;
    }
  }
}

.icon {
  i {
    @apply duration-150;
  }
}
