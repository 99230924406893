.root {
  @apply flex;

  &.disabled {
    @apply opacity-40;
  }
}

// Default Styles for Icons
.filled-icon,
.half-icon {
  @apply absolute top-0 opacity-0;

  &.disabled {
    @apply grayscale;
  }
}
.half-icon {
  clip-path: inset(0 50% 0 0);
}

// Star Button Styles
.star-button {
  @apply relative text-color-muted;

  // If Star Button is selected, and not hovered
  &[data-selected='true']:not(:hover),
  &[data-selected='true'].disabled,
  &[data-selected='true'].readonly {
    & .filled-icon {
      @apply opacity-100;
    }
  }

  // If Star Button is half selected
  &[data-halfselected='true'] {
    & .half-icon {
      @apply opacity-100;
    }
  }

  // If not disabled and not readonly, then apply hover styles
  &:not(.disabled):not(.readonly) {
    // If Star Button is hovered
    &:hover {
      transform: scale(1.2);
    }

    // If second half button is hovered, then style filled icon
    // If Star button is hovered, and does not have half button, then style filled icon
    &:has(.half-button + .half-button:hover) .filled-icon,
    &:hover:not(:has(.half-button)) .filled-icon {
      @apply opacity-100;
    }

    // If first half button is hovered, style half and filled icons
    &:has(.half-button:hover + .half-button) .half-icon {
      @apply opacity-100;
    }
    &:has(.half-button:hover + .half-button) .filled-icon {
      @apply opacity-0;
    }

    // If Star button is hovered, then style the next siblings
    &:hover ~ .star-button {
      & .filled-icon,
      & .half-icon {
        @apply opacity-0;
      }
    }
  }

  // If next siblings are hovered, or selected
  &:not(.only-selected) {
    &:has(~ .star-button[data-selected='true']),
    &:has(~ .star-button[data-halfselected='true']),
    &:not(.disabled):not(.readonly):has(~ .star-button:hover) {
      & .filled-icon {
        @apply opacity-100;
      }
    }
  }
}

// Default Filled Icons
.default-filled {
  @apply text-feedback-accent;
}

// Half Button Styles
.half-button {
  @apply absolute w-[50%] h-full z-10;

  & + .half-button {
    @apply right-0;
  }
}
