@use './animations.module.scss' as animations;

@include animations.component-appear-animations;

.content {
  @apply bg-layer-01 rounded-2xl shadow-depth-04 flex flex-col overflow-hidden;
  @apply border-outline-01 border;

  &[data-side='bottom'] {
    animation: slideUpAndFade 300ms ease-out;
  }
  &[data-side='left'] {
    animation: slideRightAndFade 300ms ease-out;
  }
  &[data-side='top'] {
    animation: slideDownAndFade 300ms ease-out;
  }
  &[data-side='right'] {
    animation: slideLeftAndFade 300ms ease-out;
  }
}
