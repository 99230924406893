@use './animations.module.scss' as animations;

@include animations.component-appear-animations;

.root {
  animation: slideDownAndFade 150ms ease-in-out;

  & [data-type="banner-component"] {
    margin-left: auto;

    & ~ [data-type="banner-component"] {
     margin-left: 0;
    }
  }
}
