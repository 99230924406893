.root {
  @apply duration-300 ease-in-out motion-reduce:transition-none;
  animation: contentShow 150ms ease-out;
}

.overlay {
  animation: overlayShow 150ms ease-out;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
