@import 'dark-styles.css';
@import 'light-styles.css';

:root {
  /*
    These primitives are maintained for legacy purposes,
    they are not used in the current design system.
   */
  --color-core-accent--h: var(--accent-h);
  --color-core-accent--s: var(--accent-s);
  --color-core-accent--l: var(--accent-l);

  --color-core-error--h: var(--alert-h);
  --color-core-error--s: var(--alert-s);
  --color-core-error--l: var(--alert-l);

  --color-core-success--h: var(--success-h);
  --color-core-success--s: var(--success-s);
  --color-core-success--l: var(--success-l);

  --color-core-warning--h: var(--warning-h);
  --color-core-warning--s: var(--warning-s);
  --color-core-warning--l: var(--warning-l);

  --color-core-smart--h: var(--smart-h);
  --color-core-smart--s: var(--smart-s);
  --color-core-smart--l: var(--smart-l);

  --background-layer-saturation: var(--neutral-s);
}

:root,
.force-dark {
  --color-black: 0, 0%, 0%;
  --color-white: 0, 0%, 100%;

  --color-gray-950: 0, 0%, 6%;
  --color-gray-910: 0, 0%, 11%;
  --color-gray-900: 0, 0%, 13%;
  --color-gray-800: 0, 0%, 16%;
  --color-gray-700: 0, 0%, 23%;
  --color-gray-600: 0, 0%, 34%;
  --color-gray-500: 0, 0%, 43%;
  --color-gray-400: 0, 0%, 64%;
  --color-gray-300: 0, 0%, 83%;
  --color-gray-200: 0, 0%, 89%;
  --color-gray-100: 0, 0%, 94%;
  --color-gray-50: 0, 0%, 99%;

  --color-red-dark: 343, 86%, 39%;
  --color-red-medium: 346, 91%, 48%;
  --color-red-light: 350, 97%, 66%;

  --color-orange-dark: 31, 84%, 30%;
  --color-orange-medium: 36, 100%, 60%;
  --color-orange-light: 31, 79%, 47%;

  --color-yellow-dark: 42, 55%, 29%;
  --color-yellow-medium: 48, 76%, 60%;
  --color-yellow-light: 43, 61%, 43%;

  --color-green-dark: 141, 60%, 26%;
  --color-green-medium: 153, 100%, 27%;
  --color-green-light: 140, 50%, 43%;

  --color-teal-dark: 172, 49%, 27%;
  --color-teal-medium: 175, 100%, 26%;
  --color-teal-light: 172, 55%, 41%;

  --color-sky-blue-dark: 191, 52%, 30%;
  --color-sky-blue-medium: 190, 100%, 30%;
  --color-sky-blue-light: 191, 95%, 74%;

  --color-blue-dark: 214, 81%, 40%;
  --color-blue-medium: 221, 100%, 58%;
  --color-blue-light: 220, 95%, 66%;

  --color-purple-dark: 290, 80%, 40%;
  --color-purple-medium: 240, 100%, 65%;
  --color-purple-light: 265, 100%, 70%;

  --color-accent-dark: var(--color-core-accent--h), var(--color-core-accent--s), 30%;
  --color-accent-medium: var(--color-core-accent--h), var(--color-core-accent--s), var(--color-core-accent--l);
  --color-accent-light: var(--color-core-accent--h), var(--color-core-accent--s), 70%;

  /* Background -> Layers */
  --color-background-layer-sunk: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 10%);
  --color-background-layer-base: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 12%);
  --color-background-layer-01: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 16%);
  --color-background-layer-02: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 18%);
  --color-background-layer-03: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-layer-04: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 22%);
  --color-background-layer-05: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 24%);

  /* Background -> Interactive */
  --color-background-interactive-primary: hsl(
    var(--color-core-accent--h),
    var(--color-core-accent--s),
    var(--color-core-accent--l)
  );
  --color-background-interactive-primary-hovered: hsl(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 2%),
    calc(var(--color-core-accent--l) + 5%)
  );
  --color-background-interactive-primary-selected: hsl(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 4%),
    calc(var(--color-core-accent--l) + 10%)
  );
  --color-background-interactive-primary-focused: hsl(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 4%),
    calc(var(--color-core-accent--l) + 10%)
  );
  --color-background-interactive-primary-disabled: hsla(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 2%),
    calc(var(--color-core-accent--l) + 5%),
    0.2
  );

  --color-background-interactive-secondary: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 24%);
  --color-background-interactive-secondary-hovered: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    26%
  );
  --color-background-interactive-secondary-selected: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    26%
  );
  --color-background-interactive-secondary-focused: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    28%
  );
  --color-background-interactive-secondary-disabled: hsla(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    28%.0.2
  );

  --color-background-interactive-tertiary: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 22%);
  --color-background-interactive-tertiary-hovered: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    24%
  );
  --color-background-interactive-tertiary-selected: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    24%
  );
  --color-background-interactive-tertiary-focused: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    26%
  );
  --color-background-interactive-tertiary-disabled: hsla(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    24%,
    0.2
  );

  --color-background-interactive-flat: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 8%, 0);
  --color-background-interactive-flat-hovered: hsl(var(--bg-interactive-tertiary-hovered));
  --color-background-interactive-flat-selected: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    22%
  );
  --color-background-interactive-flat-focused: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    22%
  );
  --color-background-interactive-flat-disabled: hsla(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    20%,
    0
  );

  --color-background-interactive-destructive: hsl(
    var(--color-core-error--h),
    var(--color-core-error--s),
    var(--color-core-error--l)
  );
  --color-background-interactive-destructive-hovered: hsl(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 2%),
    calc(var(--color-core-error--l) + 5%)
  );
  --color-background-interactive-destructive-selected: hsl(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 4%),
    calc(var(--color-core-error--l) + 10%)
  );
  --color-background-interactive-destructive-focused: hsl(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 4%),
    calc(var(--color-core-error--l) + 10%)
  );
  --color-background-interactive-destructive-disabled: hsla(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 2%),
    calc(var(--color-core-error--l) + 5%, 0.2)
  );

  --color-background-interactive-smart: hsl(
    var(--color-core-smart--h),
    var(--color-core-smart--s),
    var(--color-core-smart--l)
  );
  --color-background-interactive-smart-hovered: hsl(
    var(--color-core-smart--h),
    calc(var(--color-core-smart--s) - 2%),
    calc(var(--color-core-smart--l) + 3%)
  );
  --color-background-interactive-smart-selected: hsl(
    var(--color-core-smart--h),
    calc(var(--color-core-smart--s) - 4%),
    calc(var(--color-core-smart--l) + 7%)
  );
  --color-background-interactive-smart-focused: hsl(
    var(--color-core-smart--h),
    calc(var(--color-core-smart--s) - 4%),
    calc(var(--color-core-smart--l) + 10%)
  );
  --color-background-interactive-smart-disabled: hsla(
    var(--color-core-smart--h),
    calc(var(--color-core-smart--s) - 2%),
    calc(var(--color-core-smart--l) + 5%, 0.2)
  );

  /* Background -> Acents */
  --color-background-accent: hsl(var(--color-core-accent--h), var(--color-core-accent--s), var(--color-core-accent--l));
  --color-background-accent-issue: hsla(225, 100%, 70%, 0.1);
  --color-background-accent-ticket: hsl(var(--color-feedback-neutral));
  --color-background-accent-opportunity: hsla(155, 88%, 55%, 0.1);
  --color-background-accent-conversation: hsla(25, 100%, 60%, 0.1);

  /* Background -> feedback */

  --color-background-feedback-danger: hsla(
    var(--color-core-error--h),
    var(--color-core-error--s),
    var(--color-core-error--l),
    0.1
  );
  --color-background-feedback-warning: hsla(
    var(--color-core-warning--h),
    var(--color-core-warning--s),
    var(--color-core-warning--l),
    0.1
  );
  --color-background-feedback-success: hsla(
    var(--color-core-success--h),
    var(--color-core-success--s),
    var(--color-core-success--l),
    0.1
  );
  --color-background-feedback-smart: hsla(254, 92%, 71%, 0.1);
  --color-background-feedback-neutral: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 98%, 0.1);
  --color-background-feedback-informative: hsla(175, 100%, 26%, 0.2);

  /* Background -> Input */

  --color-background-input-empty: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 9%);
  --color-background-input-filled: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-input-focused: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 16%);
  --color-background-input-error: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-input-suggested: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-input-disabled: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 20%, 0.2);
  --color-background-input-toggle: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 24%);

  /* Background - Chart Colors */
  --chart-primary-01: hsla(250, 70%, 75%, 1);
  --chart-primary-02: hsla(250, 70%, 65%, 1);
  --chart-primary-03: hsla(250, 36%, 50%, 1);
  --chart-primary-04: hsla(250, 30%, 35%, 1);
  --chart-primary-05: hsla(248, 25%, 28%, 1);

  --chart-secondary-01: hsla(42, 90%, 70%, 1);
  --chart-secondary-02: hsla(42, 80%, 48%, 1);
  --chart-secondary-03: hsla(42, 54%, 39%, 1);
  --chart-secondary-04: hsla(40, 36%, 28%, 1);
  --chart-secondary-05: hsla(38, 20%, 22%, 1);

  /* Background - sequential colors (eg, for charts) */
  --color-background-sequential-100: hsla(240, 50%, 50%, 1);
  --color-background-sequential-200: hsla(240, 70%, 60%, 1);
  --color-background-sequential-300: hsla(240, 70%, 70%, 1);
  --color-background-sequential-400: hsla(240, 71%, 80%, 1);
  --color-background-sequential-500: hsla(240, 71%, 90%, 1);

  /* Border -> default */
  --border-default: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 22%);
  --border-default-hovered: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 24%);
  --border-default-selected: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 24%);
  --border-default-focused: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 22%);

  /* Border -> Input */

  --border-input-empty: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 21%);
  --border-input-hovered: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 23%);
  --border-input-filled: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 28%);
  --border-input-focused: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 32%);
  --border-input-checkbox: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 32%);
  --border-input-error: hsl(var(--color-core-error--h), var(--color-core-error--s), var(--color-core-error--l));
  --border-input-suggested: hsla(294, 100, 44, 0.6);
  --border-input-disabled: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 32%, 0.4);

  /* Content -> Default */
  --color-content-default-highlight: hsl(var(--color-core-accent--h), 0%, 100%);
  --color-content-default-primary: hsl(var(--color-core-accent--h), 4%, 94%);
  --color-content-default-secondary: hsl(var(--color-core-accent--h), 4%, 78%);
  --color-content-default-tertiary: hsl(var(--color-core-accent--h), 4%, 62%);
  --color-content-default-disabled: hsl(var(--color-core-accent--h), 4%, 38%);
  /* Content -> Feedback */
  --color-content-feedback-warning: hsla(
    var(--color-core-warning--h),
    var(--color-core-warning--s),
    var(--color-core-warning--l)
  );
  --color-content-feedback-danger: hsl(
    var(--color-core-error--h),
    var(--color-core-error--s),
    var(--color-core-error--l)
  );
  --color-content-feedback-success: hsl(
    var(--color-core-success--h),
    var(--color-core-success--s),
    var(--color-core-success--l)
  );
  --color-content-feedback-smart: hsl(294, 100%, 44%);
  --color-content-feedback-informative: hsl(175, 100%, 26%);
  --color-content-feedback-link: hsl(220, 96%, 70%);
  --color-content-banner-purple: hsl(265, 100%, 70%);

  /* Content -> Accent */

  --color-content-accent-issue: hsl(225, 100%, 70%);
  --color-content-accent-ticket: hsl(50, 100%, 50%);
  --color-content-accent-opportunity: hsl(155, 88%, 55%);
  --color-content-accent-conversation: hsl(25, 100%, 60%);
  --color-content-accent-customer: hsl(140, 50%, 43%);
  --color-content-accent-rev-user: hsl(24, 100%, 65%);

  --color-content-accent-product: 260, 100%, 75%;
  --color-content-accent-feature: 190, 100%, 65%;
  --color-content-accent-capability: 210, 100%, 64%;
  --color-content-accent-runnable: 45, 63%, 61%;
  --color-content-accent-linkable: 39, 100%, 55%;
  --color-content-accent-microservice: 39, 100%, 55%;
  --color-content-accent-component: 39, 100%, 55%;
  --color-content-accent-enhancement: 155, 88%, 55%;

  /* The end of derived colors */

  --bg-button-brand: var(--color-background-interactive-primary);
  --bg-button-brand-hovered: var(--color-background-interactive-primary-hovered);
  --bg-button-brand-disabled: var(--color-background-interactive-primary-disabled);

  --bg-sunk-area: var(--color-background-layer-sunk);
  --bg-base: var(--color-background-layer-base);
  --bg-overlay: var(--color-background-layer-01);
  --bg-pill: var(--color-background-layer-02);
  --bg-pill-hovered: var(--color-background-layer-03);
  --bg-pill-selected: var(--color-background-layer-04);
  --bg-lifted-area: var(--color-background-layer-03);
  --bg-lifted-area-hovered: var(--color-background-layer-04);

  /* DARK MODE VARIABLES */

  --text-color-disabled: var(--color-content-default-disabled);

  --border-hovered: var(--border-default-hovered);
  --border-selected: var(--border-default-selected);
  --border-selected-hovered: var(--border-default-selected);
  --border-input: var(--border-input-empty);
  --border-checkbox: var(--border-input-checkbox);
  --border-checkbox-disabled: var(--border-input-disabled);
  --border-separator: var(--border-default);

  --text-color-success: var(--color-content-feedback-success);
  --text-color-alert: var(--color-content-feedback-danger);
  --text-color-warning: var(--color-content-feedback-warning);
  --text-color-link: var(--color-content-feedback-link);
  --text-color-purple: var(--color-content-banner-purple);

  --text-color-issue: var(--color-content-accent-issue);
  --text-color-ticket: var(--color-feedback-neutral);
  --text-color-opportunity: var(--color-content-accent-opportunity);
  --text-color-enhancement: var(--color-content-accent-enhancement);
  --text-color-bug: var(--color-red-light);
  --text-color-request: var(--color-yellow-light);
  --text-color-problem: var(--color-orange-light);
  --text-color-conversation: var(--color-content-accent-conversation);
  --text-color-dev-user: var(--color-content-feedback-link);
  --text-color-rev-user: var(--color-content-accent-rev-user);

  --bg-badge: var(--color-background-feedback-neutral);
  --bg-badge-hovered: var(--color-background-feedback-neutral);

  --bg-menu: var(--color-background-interactive-flat);
  --bg-menu-hovered: var(--color-background-interactive-flat-hovered);
  --bg-menu-selected: var(--color-background-interactive-flat-selected);
  --bg-menu-selected-hovered: var(--color-background-interactive-flat-selected);

  --bg-area: var(--color-background-layer-01);
  --bg-area-hovered: var(--color-background-layer-01);
  --bg-area-selected: var(--color-background-layer-02);

  --bg-button-primary: var(--color-background-interactive-secondary);
  --bg-button-primary-hovered: var(--color-background-interactive-secondary-hovered);
  --bg-button-secondary: var(--color-background-interactive-tertiary);
  --bg-button-secondary-hovered: var(--color-background-interactive-tertiary-hovered);
  --bg-button-tertiary: var(--color-background-interactive-flat);
  --bg-button-tertiary-hovered: var(--color-background-interactive-flat-hovered);
  --bg-button-muted: var(--color-background-interactive-flat);
  --bg-button-muted-hovered: var(--color-background-interactive-flat-hovered);
  --bg-button-destructive: var(--color-background-interactive-destructive);
  --bg-button-destructive-hovered: var(--color-background-interactive-destructive-hovered);

  --bg-toggle: var(--color-background-input-toggle);
  --bg-toggle-active: var(--color-background-accent);
  --bg-toggle-disabled: var(--color-background-input-disabled);

  --bg-input: var(--color-background-input-empty);
  --bg-nav-search: var(--color-background-input-empty);
  --bg-menu-search: var(--color-background-input-empty);

  --bg-dotted-loader: hsla(var(--color-white), 1);
  --bg-scrollbar: hsla(var(--color-white), 0.2);
  --bg-scrollbar-hovered: hsla(var(--color-white), 0.5);
  --bg-media-overlay: hsla(var(--color-black), 0.3);

  --bg-success: var(--color-background-feedback-success);
  --bg-failure: var(--color-background-feedback-danger);
  --bg-warning: var(--color-background-feedback-warning);
  --bg-smart: var(--color-background-feedback-smart);

  --bg-chart-tooltip: var(--color-background-layer-05);
  --bg-chart-medium: hsla(240, 70%, 72%, 1);
  --bg-chart-light: hsla(240, 71%, 88%, 1);
  --border-chart-grid: var(--border-input);
  --color-content-chart: var(--color-content-default-tertiary);

  --shadow-tiny: 0px 0px 0px 1px hsla(var(--color-white), 0.1), 0px 1px 2px hsla(var(--color-black), 0.05);
  --shadow-popover: 0px 0px 0px 1px hsla(var(--color-white), 0.1), 0px 6px 12px -3px hsla(var(--color-black), 0.2);
  --shadow-modal: 0px 0px 0px 1px hsla(var(--color-white), 0.1), 0px 12px 36px hsla(var(--color-black), 0.2);
  --shadow-plug: 0px 0px 0px 1px hsla(var(--color-white), 0.1), 0px 10px 15px -3px hsla(var(--color-black), 0.02),
    0px 4px 6px -2px hsla(var(--color-black), 0.02);
  --shadow-slide-in: 0px 0px 0px 1px hsla(var(--color-white), 0.1), 0px 0px 64px hsla(var(--color-black), 0.3);

  --shadow-pill: none;
  --shadow-pill-hovered: none;
  --shadow-lifted-area: none;

  --color-trails-product: var(--color-content-accent-product);
  --color-trails-feature: var(--color-content-accent-feature);
  --color-trails-capability: var(--color-content-accent-capability);
  --color-trails-runnable: var(--color-content-accent-runnable);
  --color-trails-enhancement: var(--color-content-accent-enhancement);
  --color-trails-linkable: var(--color-content-accent-linkable);
  --color-trails-customPart: var(--color-content-accent-enhancement);
  --color-trails-microservice: var(--color-content-accent-microservice);
  --color-trails-component: var(--color-content-accent-component);

  --color-support-vistas: var(--color-content-accent-ticket);
  --color-product-vistas: var(--color-content-accent-product);
  --color-build-vistas: var(--color-content-accent-issue);
  --color-customer-vistas: var(--color-content-accent-customer);

  --page-gutter: 2.25rem;
  --side-panel-gutter: 1rem;
  --left-panel-collapsed-width: 3rem;
  --left-panel-expanded-width: 16rem;
}

@media (max-width: 639px) {
  :root {
    --page-gutter: 1rem;
    --side-panel-gutter: 1rem;
    --left-panel-expanded-width: 100vw;
  }
  .h-screen {
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
}

:root.light,
.force-light {
  --color-black: 0, 0%, 0%;
  --color-white: 0, 0%, 100%;

  --color-gray-950: 0, 0%, 6%;
  --color-gray-910: 0, 0%, 11%;
  --color-gray-900: 0, 0%, 13%;
  --color-gray-800: 0, 0%, 16%;
  --color-gray-700: 0, 0%, 23%;
  --color-gray-600: 0, 0%, 34%;
  --color-gray-500: 0, 0%, 43%;
  --color-gray-400: 0, 0%, 64%;
  --color-gray-300: 0, 0%, 83%;
  --color-gray-200: 0, 0%, 89%;
  --color-gray-100: 0, 0%, 94%;
  --color-gray-50: 0, 0%, 99%;

  --color-red-dark: 343, 86%, 39%;
  --color-red-medium: 346, 91%, 48%;
  --color-red-light: 350, 97%, 66%;

  --color-orange-dark: 31, 84%, 30%;
  --color-orange-medium: 36, 100%, 24%;
  --color-orange-light: 31, 79%, 47%;

  --color-yellow-dark: 42, 55%, 29%;
  --color-yellow-medium: 48, 76%, 60%;
  --color-yellow-light: 43, 61%, 43%;

  --color-green-dark: 141, 60%, 26%;
  --color-green-medium: 153, 100%, 27%;
  --color-green-light: 140, 50%, 43%;

  --color-teal-dark: 172, 49%, 27%;
  --color-teal-medium: 175, 100%, 26%;
  --color-teal-light: 172, 55%, 41%;

  --color-sky-blue-dark: 191, 57%, 45%;
  --color-sky-blue-medium: 190, 100%, 30%;
  --color-sky-blue-light: 191, 84%, 36%;

  --color-blue-dark: 214, 81%, 40%;
  --color-blue-medium: 221, 100%, 58%;
  --color-blue-light: 220, 95%, 66%;

  --color-purple-dark: 290, 80%, 40%;
  --color-purple-medium: 240, 100%, 60%;
  --color-purple-light: 265, 100%, 60%;

  /* The start of derived colors */

  --background-layer-saturation: 10%;

  /* Background -> Layers */
  --color-background-layer-sunk: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 98%);
  --color-background-layer-base: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 100%);
  --color-background-layer-01: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 96%);
  --color-background-layer-02: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 94%);
  --color-background-layer-03: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 88%);
  --color-background-layer-04: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 86%);
  --color-background-layer-05: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 82%);

  /* Background -> Interactive */
  --color-background-interactive-primary: hsl(
    var(--color-core-accent--h),
    var(--color-core-accent--s),
    var(--color-core-accent--l)
  );
  --color-background-interactive-primary-hovered: hsl(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 2%),
    calc(var(--color-core-accent--l) + 5%)
  );
  --color-background-interactive-primary-selected: hsl(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 4%),
    calc(var(--color-core-accent--l) + 10%)
  );
  --color-background-interactive-primary-focused: hsl(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 4%),
    calc(var(--color-core-accent--l) + 10%)
  );
  --color-background-interactive-primary-disabled: hsla(
    var(--color-core-accent--h),
    calc(var(--color-core-accent--s) - 2%),
    calc(var(--color-core-accent--l) + 5%),
    0.2
  );

  --color-background-interactive-secondary: hsl(var(--color-core-accent--h), 32%, 100%);
  --color-background-interactive-secondary-hovered: hsl(var(--color-core-accent--h), 32%, 98%);
  --color-background-interactive-secondary-selected: hsl(var(--color-core-accent--h), 32%, 94%);
  --color-background-interactive-secondary-focused: hsl(var(--color-core-accent--h), 32%, 94%);
  --color-background-interactive-secondary-disabled: hsla(var(--color-core-accent--h), 32%, 100%.0.2);

  --color-background-interactive-tertiary: hsl(var(--color-core-accent--h), 10%, 92%);
  --color-background-interactive-tertiary-hovered: hsl(var(--color-core-accent--h), 10%, 88%);
  --color-background-interactive-tertiary-selected: hsl(var(--color-core-accent--h), 32%, 88%);
  --color-background-interactive-tertiary-focused: hsl(var(--color-core-accent--h), 32%, 92%);
  --color-background-interactive-tertiary-disabled: hsla(var(--color-core-accent--h), 32%, 90%, 0.2);

  --color-background-interactive-flat: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 70%, 0);
  --color-background-interactive-flat-hovered: hsl(var(--bg-interactive-tertiary-hovered));
  --color-background-interactive-flat-selected: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    90%
  );
  --color-background-interactive-flat-focused: hsl(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    92%
  );
  --color-background-interactive-flat-disabled: hsla(
    var(--color-core-accent--h),
    var(--background-layer-saturation),
    16%,
    0
  );

  --color-background-interactive-destructive: hsl(
    var(--color-core-error--h),
    var(--color-core-error--s),
    var(--color-core-error--l)
  );
  --color-background-interactive-destructive-hovered: hsl(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 2%),
    calc(var(--color-core-error--l) + 5%)
  );
  --color-background-interactive-destructive-selected: hsl(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 4%),
    calc(var(--color-core-error--l) + 10%)
  );
  --color-background-interactive-destructive-focused: hsl(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 4%),
    calc(var(--color-core-error--l) + 10%)
  );
  --color-background-interactive-destructive-disabled: hsla(
    var(--color-core-error--h),
    calc(var(--color-core-error--s) - 2%),
    calc(var(--color-core-error--l) + 5%, 0.2)
  );

  /* Background -> Acents */
  --color-background-accent: hsl(var(--color-core-accent--h), var(--color-core-accent--s), var(--color-core-accent--l));
  --color-background-accent-issue: hsla(240, 100%, 60%, 0.1);
  --color-background-accent-ticket: hsl(var(--color-feedback-neutral));
  --color-background-accent-opportunity: hsla(155, 88%, 35%, 0.1);
  --color-background-accent-conversation: hsla(25, 100%, 60%, 0.1);

  /* Background -> feedback */

  --color-background-feedback-danger: hsla(
    var(--color-core-error--h),
    var(--color-core-error--s),
    var(--color-core-error--l),
    0.1
  );
  --color-background-feedback-warning: hsla(
    var(--color-core-warning--h),
    var(--color-core-warning--s),
    var(--color-core-warning--l),
    0.1
  );
  --color-background-feedback-success: hsla(
    var(--color-core-success--h),
    var(--color-core-success--s),
    var(--color-core-success--l),
    0.1
  );
  --color-background-feedback-smart: hsla(254, 92%, 71%, 0.1);
  --color-background-feedback-neutral: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 98%, 0.1);
  --color-background-feedback-informative: hsla(175, 100%, 26%, 0.2);

  /* Background - Chart Colors */
  --chart-primary-01: hsla(250, 65%, 46%, 1);
  --chart-primary-02: hsla(250, 80%, 64%, 1);
  --chart-primary-03: hsla(250, 80%, 78%, 1);
  --chart-primary-04: hsla(251, 82%, 89%, 1);
  --chart-primary-05: hsla(252, 79%, 96%, 1);

  --chart-secondary-01: hsla(43, 89%, 42%, 1);
  --chart-secondary-02: hsla(43, 89%, 52%, 1);
  --chart-secondary-03: hsla(43, 89%, 71%, 1);
  --chart-secondary-04: hsla(42, 89%, 86%, 1);
  --chart-secondary-05: hsla(41, 92%, 95%, 1);

  /* Background -> Input */

  --color-background-input-empty: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 100%);
  --color-background-input-filled: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-input-focused: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-input-error: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-input-suggested: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 20%);
  --color-background-input-disabled: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 10%, 0.2);
  --color-background-input-toggle: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 80%);

  /* Border -> default */
  --border-default: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 90%);
  --border-default-hovered: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 84%);
  --border-default-selected: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 78%);
  --border-default-focused: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 72%);

  /* Border -> Input */

  --border-input-empty: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 90%);
  --border-input-hovered: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 84%);
  --border-input-filled: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 78%);
  --border-input-focused: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 72%);
  --border-input-checkbox: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 72%);
  --border-input-error: hsl(var(--color-core-error--h), var(--color-core-error--s), var(--color-core-error--l));
  --border-input-suggested: hsla(294, 100, 44, 0.6);
  --border-input-disabled: hsl(var(--color-core-accent--h), var(--background-layer-saturation), 72%, 0.4);

  /* Content -> Default */
  --color-content-default-highlight: hsl(var(--color-core-accent--h), 0%, 0%);
  --color-content-default-primary: hsl(var(--color-core-accent--h), 4%, 15%);
  --color-content-default-secondary: hsl(var(--color-core-accent--h), 4%, 30%);
  --color-content-default-tertiary: hsl(var(--color-core-accent--h), 4%, 45%);
  --color-content-default-disabled: hsl(var(--color-core-accent--h), 4%, 75%);

  /* Content -> Feedback */
  --color-content-feedback-warning: hsla(
    var(--color-core-warning--h),
    var(--color-core-warning--s),
    var(--color-core-warning--l)
  );
  --color-content-feedback-danger: hsl(
    var(--color-core-error--h),
    var(--color-core-error--s),
    var(--color-core-error--l)
  );
  --color-content-feedback-success: hsl(
    var(--color-core-success--h),
    var(--color-core-success--s),
    var(--color-core-success--l)
  );
  --color-content-feedback-smart: hsl(294, 100%, 44%);
  --color-content-feedback-informative: hsl(175, 100%, 26%);
  --color-content-feedback-link: hsl(238, 89%, 35%);
  --color-content-banner-purple: hsl(265, 100%, 70%);

  /* Content -> Accent */

  --color-content-accent-issue: hsl(240, 100%, 60%);
  --color-content-accent-ticket: hsl(40, 100%, 40%);
  --color-content-accent-opportunity: hsl(155, 88%, 35%);
  --color-content-accent-conversation: hsl(26, 100%, 49%);
  --color-content-accent-customer: hsl(140, 100%, 30%);

  --color-content-accent-product: 260, 100%, 50%;
  --color-content-accent-feature: 190, 100%, 35%;
  --color-content-accent-capability: 210, 100%, 44%;
  --color-content-accent-runnable: 45, 63%, 31%;
  --color-content-accent-linkable: 39, 79%, 47%;
  --color-content-accent-microservice: 39, 79%, 47%;
  --color-content-accent-component: 39, 79%, 47%;
  --color-content-accent-enhancement: 155, 88%, 35%;

  /* The end of derived colors */

  --bg-button-brand: var(--color-background-interactive-primary);
  --bg-button-brand-hovered: var(--color-background-interactive-primary-hovered);
  --bg-button-brand-disabled: var(--color-background-interactive-primary-disabled);

  --bg-sunk-area: var(--color-background-layer-sunk);
  --bg-base: var(--color-background-layer-base);
  --bg-overlay: var(--color-background-layer-base);
  --bg-pill: var(--color-background-layer-02);
  --bg-pill-hovered: var(--color-background-layer-03);
  --bg-pill-selected: var(--color-background-layer-04);
  --bg-lifted-area: var(--color-background-layer-base);
  --bg-lifted-area-hovered: var(--color-background-layer-01);

  --text-color-disabled: var(--color-content-default-disabled);

  --border-hovered: var(--border-default-hovered);
  --border-selected: var(--border-default-selected);
  --border-selected-hovered: var(--border-default-selected);
  --border-input: var(--border-input-empty);

  --border-checkbox: var(--border-input-checkbox);
  --border-checkbox-disabled: var(--border-input-disabled);
  --border-separator: var(--border-default);

  --text-color-success: var(--color-content-feedback-success);
  --text-color-alert: var(--color-content-feedback-danger);
  --text-color-warning: var(--color-content-feedback-warning);
  --text-color-link: var(--color-content-feedback-link);
  --text-color-purple: var(--color-content-banner-purple);

  --text-color-issue: var(--color-content-accent-issue);
  --text-color-ticket: var(--color-content-accent-ticket);
  --text-color-opportunity: var(--color-content-accent-opportunity);
  --text-color-enhancement: var(--color-content-accent-enhancement);
  --text-color-bug: var(--color-red-light);
  --text-color-request: var(--color-yellow-light);
  --text-color-problem: var(--color-orange-light);
  --text-color-conversation: var(--color-content-accent-conversation);
  --text-color-dev-user: var(--color-content-feedback-link);
  --text-color-rev-user: var(--color-content-accent-rev-user);

  --bg-badge: var(--color-background-feedback-neutral);
  --bg-badge-hovered: var(--color-background-feedback-neutral);

  --bg-menu: var(--color-background-interactive-flat);
  --bg-menu-hovered: var(--color-background-interactive-flat-hovered);
  --bg-menu-selected: var(--color-background-interactive-flat-selected);
  --bg-menu-selected-hovered: var(--color-background-interactive-flat-selected);

  --bg-area: var(--color-background-layer-01);
  --bg-area-hovered: var(--color-background-layer-02);
  --bg-area-selected: var(--color-background-layer-02);

  --bg-button-primary: var(--color-background-interactive-secondary);
  --bg-button-primary-hovered: var(--color-background-interactive-secondary-hovered);
  --bg-button-secondary: var(--color-background-interactive-tertiary);
  --bg-button-secondary-hovered: var(--color-background-interactive-tertiary-hovered);
  --bg-button-tertiary: var(--color-background-interactive-flat);
  --bg-button-tertiary-hovered: var(--color-background-interactive-flat-hovered);
  --bg-button-muted: var(--color-background-interactive-flat);
  --bg-button-muted-hovered: var(--color-background-interactive-flat-hovered);
  --bg-button-destructive: var(--color-background-interactive-destructive);
  --bg-button-destructive-hovered: var(--color-background-interactive-destructive-hovered);

  --bg-toggle: var(--color-background-input-toggle);
  --bg-toggle-active: var(--color-background-accent);
  --bg-toggle-disabled: var(--color-background-input-disabled);
  --bg-input: var(--color-background-input-empty);
  --bg-nav-search: var(--color-background-input-empty);
  --bg-menu-search: var(--color-background-input-empty);

  --bg-dotted-loader: hsla(var(--color-black), 0.5);
  --bg-scrollbar: hsla(var(--color-black), 0.2);
  --bg-scrollbar-hovered: hsla(var(--color-black), 0.5);
  --bg-media-overlay: hsla(var(--color-black), 0.3);

  --bg-success: var(--color-background-feedback-success);
  --bg-failure: var(--color-background-feedback-danger);
  --bg-warning: var(--color-background-feedback-warning);
  --bg-smart: var(--color-background-feedback-smart);

  --bg-chart-tooltip: var(--color-background-layer-base);
  --bg-chart-medium: hsla(240, 70%, 72%, 1);
  --bg-chart-light: hsla(240, 71%, 88%, 1);
  --border-chart-grid: var(--border-input);
  --color-content-chart: var(--color-content-default-tertiary);

  --shadow-tiny: 0px 0px 0px 1px hsla(var(--color-black), 0.05), 0px 2px 4px hsla(var(--color-black), 0.05);
  --shadow-popover: 0px 0px 0px 1px hsla(var(--color-black), 0.05), 0px 6px 12px -3px hsla(var(--color-black), 0.2);
  --shadow-modal: 0px 0px 0px 1px hsla(var(--color-black), 0.05), 0px 12px 36px hsla(var(--color-black), 0.2);
  --shadow-plug: 0px 0px 0px 1px hsla(var(--color-black), 0.05), 0px 10px 15px -3px hsla(var(--color-black), 0.02),
    0px 4px 6px -2px hsla(var(--color-black), 0.02);
  --shadow-slide-in: 0px 0px 0px 1px hsla(var(--color-black), 0.05), 0px 0px 64px hsla(var(--color-black), 0.15);

  --shadow-pill: 0px 0px 0px 1px hsla(var(--color-black), 0.1);
  --shadow-pill-hovered: 0px 0px 0px 1px hsla(var(--color-black), 0.1), 0px 2px 4px hsla(var(--color-black), 0.05);
  --shadow-lifted-area: 0px 0px 0px 1px hsla(var(--color-black), 0.1), 0px 4px 8px -4px hsla(var(--color-black), 0.05);

  --color-trails-product: var(--color-content-accent-product);
  --color-trails-feature: var(--color-content-accent-feature);
  --color-trails-capability: var(--color-content-accent-capability);
  --color-trails-runnable: var(--color-content-accent-runnable);
  --color-trails-enhancement: var(--color-content-accent-enhancement);
  --color-trails-linkable: var(--color-content-accent-linkable);
  --color-trails-customPart: var(--color-content-accent-enhancement);
  --color-trails-microservice: var(--color-content-accent-microservice);
  --color-trails-component: var(--color-content-accent-component);

  --color-support-vistas: var(--color-content-accent-ticket);
  --color-product-vistas: var(--color-content-accent-product);
  --color-build-vistas: var(--color-content-accent-issue);
  --color-customer-vistas: var(--color-content-accent-customer);
}

/* This will force links to be readable in bubbles until we do DLS23 redesign */
:root .force-dark.bubble-theme a {
  --text-color-link: hsl(0, 100%, 100%);
  text-decoration: underline;
}

body {
  @apply text-color-primary bg-layer-00 antialiased;
}

input {
  @apply placeholder:text-color-tertiary;
  @apply bg-layer-00;
}

footer {
  @apply bg-layer-01;
}

::-webkit-scrollbar {
  @apply w-[9px] h-[9px];
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-scrollbar bg-clip-content rounded-[4.5px] border-solid border-[3px] border-transparent;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-scrollbar-hovered;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.bg-fading-base {
  --bg-transparent: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 12%, 0);
  background: linear-gradient(180deg, var(--bg-base), var(--bg-transparent) 100%);
}

.bg-fading-overlay {
  --bg-transparent: hsla(var(--color-core-accent--h), var(--background-layer-saturation), 12%, 0);
  background: linear-gradient(180deg, var(--bg-overlay), var(--bg-transparent) 100%);
}

.bg-fading-nav {
  background: linear-gradient(180deg, hsla(var(--bg-nav-gradient), 1), hsla(var(--bg-nav-gradient), 0) 100%);
}

circle,
ellipse,
line,
path,
polygon,
polyline,
rect {
  vector-effect: non-scaling-stroke;
}

/* TODO: Handle it with global */
.dr-select .react-select__control {
  @apply bg-pill-hovered;
  @apply border-0;
  @apply rounded-lg;
  @apply text-color-highlight;
  /* ring-1 ring-white-50 ring-offset-2 ring-offset-black-90; */
}

.dr-select .react-select__control:hover {
  @apply bg-white-12;
  /* ring-1 ring-white-50 ring-offset-2 ring-offset-black-90; */
}

.dr-select .react-select__control:focus-within {
  @apply ring-white-50;
  @apply ring-offset-2;
  @apply ring-offset-black-90;
}

.dr-select .react-select__menu {
  @apply bg-overlay;
  @apply rounded-lg;
  @apply overflow-hidden;
}

.dr-select .react-select__option--is-focused {
  @apply bg-white-12;
  @apply text-color-highlight;
}

.dr-select .react-select__option--is-selected {
  @apply bg-white-12;
  @apply text-color-highlight;
}

.dr-select .react-select__single-value {
  @apply text-color-secondary;
}

.dr-select .react-select__input {
  @apply shadow-none;
  @apply border-0;
  @apply !ring-0;
}

.dr-select .react-select__option {
  @apply p-0;
}

.dr-select .react-select__menu-list {
  @apply overflow-hidden;
  @apply p-0;
}

.dr-select .react-select__indicator-separator {
  @apply hidden;
}

@keyframes move-right {
  to {
    background-position-x: -200%;
  }
}

.skeleton-loader {
  background: var(--bg-area);
  background: linear-gradient(
    110deg,
    var(--color-background-layer-01),
    var(--color-background-layer-02),
    var(--color-background-layer-03)
  );
  animation: 1.5s move-right linear infinite;
  background-size: 200% 100%;
}
