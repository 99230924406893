@container aside-container (max-width : 500px) {
  .blockAccordionRoot {
    [data-view-section='aside'] & {
      @apply px-4;
    }
  }

  .separator {
    [data-view-section='aside'] & {
      @apply mx-4;
    }
  }
}

@container main-container (max-width : 500px) {
  .blockAccordionRoot {
    [data-view-section='main'] & {
      @apply px-4;
    }
  }
  .separator {
    [data-view-section='main'] & {
      @apply mx-4;
    }
  }
}

.shiftToMainOnHideAside {
  [data-view-section='main'] & {
    [data-aside='close'] & {
      @apply block;
    }

    [data-aside='open'] & {
      @apply hidden;
    }
    [data-aside='overlay'] & {
      @apply hidden;
    }
  }
}
@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}
@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    opacity: 1;
    height: var(--radix-accordion-content-height);
  }
}

.trigger {
  &[data-state='open'] {
    & [data-chevron-down-icon='true'] {
      @apply rotate-180;
    }
  }
}

.icon {
  i {
    @apply duration-300;
  }
}

.blockAccordionContent {
  &[data-state='open'] {
    animation: slideDown 150ms ease-in-out;
  }
  &[data-state='closed'] {
    animation: slideUp 100ms ease-in-out;
  }
}
