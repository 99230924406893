@import 'primitives.css';

:root {
  --shade-difference: 4%;

  --accent-100: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 5 * var(--shade-difference));
  --accent-200: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 4 * var(--shade-difference));
  --accent-300: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 3 * var(--shade-difference));
  --accent-400: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 2 * var(--shade-difference));
  --accent-500: var(--accent-h) var(--accent-s) var(--accent-l);
  --accent-600: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 2 * var(--shade-difference));
  --accent-700: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 3 * var(--shade-difference));
  --accent-800: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 4 * var(--shade-difference));
  --accent-900: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 5 * var(--shade-difference));

  --warning-100: var(--warning-h) var(--warning-s) calc(var(--warning-l) - 5 * var(--shade-difference));
  --warning-200: var(--warning-h) var(--warning-s) calc(var(--warning-l) - 4 * var(--shade-difference));
  --warning-300: var(--warning-h) var(--warning-s) calc(var(--warning-l) - 3 * var(--shade-difference));
  --warning-400: var(--warning-h) var(--warning-s) calc(var(--warning-l) - 2 * var(--shade-difference));
  --warning-500: var(--warning-h) var(--warning-s) var(--warning-l);
  --warning-600: var(--warning-h) var(--warning-s) calc(var(--warning-l) + 2 * var(--shade-difference));
  --warning-700: var(--warning-h) var(--warning-s) calc(var(--warning-l) + 3 * var(--shade-difference));
  --warning-800: var(--warning-h) var(--warning-s) calc(var(--warning-l) + 4 * var(--shade-difference));
  --warning-900: var(--warning-h) var(--warning-s) calc(var(--warning-l) + 5 * var(--shade-difference));

  --success-100: var(--success-h) var(--success-s) calc(var(--success-l) - 5 * var(--shade-difference));
  --success-200: var(--success-h) var(--success-s) calc(var(--success-l) - 4 * var(--shade-difference));
  --success-300: var(--success-h) var(--success-s) calc(var(--success-l) - 3 * var(--shade-difference));
  --success-400: var(--success-h) var(--success-s) calc(var(--success-l) - 2 * var(--shade-difference));
  --success-500: var(--success-h) var(--success-s) var(--success-l);
  --success-600: var(--success-h) var(--success-s) calc(var(--success-l) + 2 * var(--shade-difference));
  --success-700: var(--success-h) var(--success-s) calc(var(--success-l) + 3 * var(--shade-difference));
  --success-800: var(--success-h) var(--success-s) calc(var(--success-l) + 4 * var(--shade-difference));
  --success-900: var(--success-h) var(--success-s) calc(var(--success-l) + 5 * var(--shade-difference));

  --smart-100: var(--smart-h) var(--smart-s) calc(var(--smart-l) - 5 * var(--shade-difference));
  --smart-200: var(--smart-h) var(--smart-s) calc(var(--smart-l) - 4 * var(--shade-difference));
  --smart-300: var(--smart-h) var(--smart-s) calc(var(--smart-l) - 3 * var(--shade-difference));
  --smart-400: var(--smart-h) var(--smart-s) calc(var(--smart-l) - 2 * var(--shade-difference));
  --smart-500: var(--smart-h) var(--smart-s) var(--smart-l);
  --smart-600: var(--smart-h) var(--smart-s) calc(var(--smart-l) + 2 * var(--shade-difference));
  --smart-700: var(--smart-h) var(--smart-s) calc(var(--smart-l) + 3 * var(--shade-difference));
  --smart-800: var(--smart-h) var(--smart-s) calc(var(--smart-l) + 4 * var(--shade-difference));
  --smart-900: var(--smart-h) var(--smart-s) calc(var(--smart-l) + 5 * var(--shade-difference));

  --alert-100: var(--alert-h) var(--alert-s) calc(var(--alert-l) - 5 * var(--shade-difference));
  --alert-200: var(--alert-h) var(--alert-s) calc(var(--alert-l) - 4 * var(--shade-difference));
  --alert-300: var(--alert-h) var(--alert-s) calc(var(--alert-l) - 3 * var(--shade-difference));
  --alert-400: var(--alert-h) var(--alert-s) calc(var(--alert-l) - 2 * var(--shade-difference));
  --alert-500: var(--alert-h) var(--alert-s) var(--alert-l);
  --alert-600: var(--alert-h) var(--alert-s) calc(var(--alert-l) + 2 * var(--shade-difference));
  --alert-700: var(--alert-h) var(--alert-s) calc(var(--alert-l) + 3 * var(--shade-difference));
  --alert-800: var(--alert-h) var(--alert-s) calc(var(--alert-l) + 4 * var(--shade-difference));
  --alert-900: var(--alert-h) var(--alert-s) calc(var(--alert-l) + 5 * var(--shade-difference));

  --neutral-80: var(--neutral-h) var(--neutral-s) 8%;
  --neutral-100: var(--neutral-h) var(--neutral-s) 10%;
  --neutral-120: var(--neutral-h) var(--neutral-s) 12%;
  --neutral-140: var(--neutral-h) var(--neutral-s) 14%;
  --neutral-160: var(--neutral-h) var(--neutral-s) 16%;
  --neutral-180: var(--neutral-h) var(--neutral-s) 18%;
  --neutral-200: var(--neutral-h) var(--neutral-s) 20%;
  --neutral-220: var(--neutral-h) var(--neutral-s) 22%;

  --neutral-240: var(--neutral-h) var(--neutral-s) 24%;
  --neutral-280: var(--neutral-h) var(--neutral-s) 28%;
  --neutral-320: var(--neutral-h) var(--neutral-s) 32%;
  --neutral-360: var(--neutral-h) var(--neutral-s) 36%;

  --neutral-400: var(--neutral-h) var(--neutral-s) 40%;
  --neutral-480: var(--neutral-h) var(--neutral-s) 48%;
  --neutral-560: var(--neutral-h) var(--neutral-s) 56%;
  --neutral-620: var(--neutral-h) var(--neutral-s) 62%;

  --neutral-700: var(--neutral-h) var(--neutral-s) 70%;
  --neutral-740: var(--neutral-h) var(--neutral-s) 74%;
  --neutral-780: var(--neutral-h) var(--neutral-s) 78%;
  --neutral-820: var(--neutral-h) var(--neutral-s) 82%;

  --neutral-860: var(--neutral-h) var(--neutral-s) 86%;
  --neutral-880: var(--neutral-h) var(--neutral-s) 88%;
  --neutral-900: var(--neutral-h) var(--neutral-s) 90%;
  --neutral-920: var(--neutral-h) var(--neutral-s) 92%;
  --neutral-940: var(--neutral-h) var(--neutral-s) 94%;
  --neutral-960: var(--neutral-h) var(--neutral-s) 96%;
  --neutral-980: var(--neutral-h) var(--neutral-s) 98%;
  --neutral-1000: var(--neutral-h) var(--neutral-s) 100%;

  --secondary-100: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - 5 * var(--shade-difference));
  --secondary-200: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - 4 * var(--shade-difference));
  --secondary-300: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - 3 * var(--shade-difference));
  --secondary-400: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) - 2 * var(--shade-difference));
  --secondary-500: var(--secondary-h) var(--secondary-s) var(--secondary-l);
  --secondary-600: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) + 2 * var(--shade-difference));
  --secondary-700: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) + 3 * var(--shade-difference));
  --secondary-800: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) + 4 * var(--shade-difference));
  --secondary-900: var(--secondary-h) var(--secondary-s) calc(var(--secondary-l) + 5 * var(--shade-difference));

  --red-1000: 358 89% 93%;
  --red-950: 358 83% 91%;
  --red-900: 358 90% 88%;
  --red-850: 358 90% 84%;
  --red-800: 358 94% 79%;
  --red-750: 356 97% 77%;
  --red-700: 357 92% 72%;
  --red-650: 357 95% 66%;
  --red-600: 355 95% 62%;
  --red-550: 355 95% 58%;
  --red-500: 355 95% 58%;
  --red-450: 354 74% 50%;
  --red-400: 353 74% 42%;
  --red-350: 351 77% 37%;
  --red-300: 352 74% 34%;
  --red-250: 352 74% 34%;
  --red-200: 353 63% 25%;
  --red-150: 351 64% 21%;
  --red-100: 350 78% 17%;
  --orange-1000: 22 100% 91%;
  --orange-950: 22 89% 86%;
  --orange-900: 23 92% 79%;
  --orange-850: 23 94% 74%;
  --orange-800: 23 96% 69%;
  --orange-750: 23 98% 67%;
  --orange-700: 25 96% 63%;
  --orange-650: 26 96% 58%;
  --orange-600: 27 90% 54%;
  --orange-550: 27 80% 51%;
  --orange-500: 27 73% 48%;
  --orange-450: 28 71% 46%;
  --orange-400: 29 81% 40%;
  --orange-350: 27 75% 39%;
  --orange-300: 27 70% 35%;
  --orange-250: 26 72% 31%;
  --orange-200: 27 74% 26%;
  --orange-150: 28 74% 23%;
  --orange-100: 27 85% 20%;
  --yellow-1000: 51 90% 88%;
  --yellow-950: 47 86% 83%;
  --yellow-900: 46 85% 76%;
  --yellow-850: 43 89% 69%;
  --yellow-800: 43 79% 64%;
  --yellow-750: 44 74% 58%;
  --yellow-700: 44 69% 53%;
  --yellow-650: 44 64% 50%;
  --yellow-600: 44 67% 47%;
  --yellow-550: 44 75% 43%;
  --yellow-500: 44 71% 42%;
  --yellow-450: 42 65% 41%;
  --yellow-400: 41 61% 39%;
  --yellow-350: 41 60% 36%;
  --yellow-300: 39 61% 34%;
  --yellow-250: 41 75% 28%;
  --yellow-200: 41 75% 28%;
  --yellow-150: 41 79% 24%;
  --yellow-100: 40 75% 22%;
  --lime-1000: 58 98% 79%;
  --lime-950: 60 78% 72%;
  --lime-900: 61 74% 64%;
  --lime-850: 65 68% 56%;
  --lime-800: 66 58% 52%;
  --lime-750: 66 67% 45%;
  --lime-700: 67 76% 39%;
  --lime-650: 67 68% 38%;
  --lime-600: 67 65% 36%;
  --lime-550: 67 65% 34%;
  --lime-500: 67 64% 32%;
  --lime-450: 67 78% 29%;
  --lime-400: 69 41% 30%;
  --lime-350: 67 56% 23%;
  --lime-300: 67 62% 20%;
  --lime-250: 69 48% 19%;
  --lime-200: 68 48% 16%;
  --lime-150: 68 43% 14%;
  --lime-100: 68 54% 11%;
  --green-1000: 111 84% 90%;
  --green-950: 113 78% 81%;
  --green-900: 116 69% 72%;
  --green-850: 121 64% 65%;
  --green-800: 121 64% 65%;
  --green-750: 126 56% 58%;
  --green-700: 132 49% 52%;
  --green-650: 136 47% 48%;
  --green-600: 140 50% 43%;
  --green-550: 153 100% 27%;
  --green-500: 146 73% 28%;
  --green-450: 141 60% 26%;
  --green-400: 138 64% 23%;
  --green-350: 134 67% 19%;
  --green-300: 129 73% 16%;
  --green-250: 123 63% 14%;
  --green-200: 117 70% 10%;
  --green-150: 112 78% 7%;
  --green-100: 109 81% 4%;
  --teal-1000: 158 75% 91%;
  --teal-950: 162 83% 77%;
  --teal-900: 164 66% 71%;
  --teal-850: 166 58% 65%;
  --teal-800: 167 50% 59%;
  --teal-750: 169 45% 52%;
  --teal-700: 171 47% 46%;
  --teal-650: 172 55% 41%;
  --teal-600: 174 70% 34%;
  --teal-550: 175 100% 26%;
  --teal-500: 174 57% 29%;
  --teal-450: 172 49% 27%;
  --teal-400: 169 28% 28%;
  --teal-350: 168 40% 22%;
  --teal-300: 168 37% 19%;
  --teal-250: 167 34% 15%;
  --teal-200: 163 33% 12%;
  --teal-150: 160 33% 9%;
  --teal-100: 160 33% 5%;
  --sky-1000: 186 71% 92%;
  --sky-950: 188 74% 84%;
  --sky-900: 188 76% 74%;
  --sky-850: 188 68% 68%;
  --sky-800: 189 60% 62%;
  --sky-750: 190 54% 56%;
  --sky-700: 190 50% 50%;
  --sky-650: 191 57% 45%;
  --sky-600: 191 72% 39%;
  --sky-550: 190 100% 30%;
  --sky-500: 191 60% 33%;
  --sky-450: 191 52% 30%;
  --sky-400: 192 29% 29%;
  --sky-350: 190 42% 23%;
  --sky-300: 189 39% 20%;
  --sky-250: 188 35% 16%;
  --sky-200: 188 33% 13%;
  --sky-150: 188 32% 9%;
  --sky-100: 186 36% 5%;
  --blue-1000: 215 76% 95%;
  --blue-950: 215 83% 91%;
  --blue-900: 217 83% 86%;
  --blue-850: 217 87% 82%;
  --blue-800: 216 88% 77%;
  --blue-750: 218 90% 73%;
  --blue-700: 219 92% 69%;
  --blue-650: 220 95% 66%;
  --blue-600: 220 97% 62%;
  --blue-550: 221 100% 58%;
  --blue-500: 215 87% 47%;
  --blue-450: 214 81% 40%;
  --blue-400: 213 76% 35%;
  --blue-350: 211 74% 28%;
  --blue-300: 210 72% 24%;
  --blue-250: 208 71% 19%;
  --blue-200: 207 71% 15%;
  --blue-150: 206 74% 10%;
  --blue-100: 206 76% 6%;
  --violet-1000: 266 70% 96%;
  --violet-950: 265 78% 93%;
  --violet-900: 240 79% 89%;
  --violet-850: 243 82% 85%;
  --violet-800: 247 86% 81%;
  --violet-750: 251 88% 76%;
  --violet-700: 254 92% 71%;
  --violet-650: 258 94% 65%;
  --violet-600: 262 97% 53%;
  --violet-550: 264 100% 44%;
  --violet-500: 262 87% 43%;
  --violet-450: 260 80% 40%;
  --violet-400: 258 76% 36%;
  --violet-350: 256 74% 32%;
  --violet-300: 253 72% 28%;
  --violet-250: 252 72% 24%;
  --violet-200: 249 72% 20%;
  --violet-150: 246 76% 15%;
  --violet-100: 242 80% 10%;
  --pink-1000: 322 80% 96%;
  --pink-950: 319 81% 92%;
  --pink-900: 320 82% 87%;
  --pink-850: 322 86% 84%;
  --pink-800: 322 89% 79%;
  --pink-750: 323 91% 74%;
  --pink-700: 323 94% 69%;
  --pink-650: 322 96% 63%;
  --pink-600: 320 94% 52%;
  --pink-550: 320 100% 45%;
  --pink-500: 320 90% 42%;
  --pink-450: 319 83% 37%;
  --pink-400: 318 79% 34%;
  --pink-350: 317 77% 29%;
  --pink-300: 316 75% 25%;
  --pink-250: 316 73% 21%;
  --pink-200: 315 74% 16%;
  --pink-150: 314 77% 12%;
  --pink-100: 311 80% 8%;
}
