@use '~@ag-grid-community/styles' as ag;
@import '@szhsin/react-menu/dist/core.css';
@import '@szhsin/react-menu/dist/transitions/slide.css';
@import '@szhsin/react-menu/dist/index.css';
@import '@szhsin/react-menu/dist/theme-dark.css';
$header-height: 32px;

@include ag.grid-styles(
  (
    theme: alpine-dark,
    alpine-active-color: var(--text-color-secondary),
    background-color: transparent,
    border-color: black,
    border-radius: 0px,
    borders-critical: true,
    borders: false,
    card-shadow: none,
    cell-horizontal-border: none,
    cell-horizontal-padding: 8px,
    chip-background-color: var(--bg-pill),
    column-hover-color: transparent,
    control-panel-background-color: transparent,
    disabled-foreground-color: var(--text-color-secondary),
    font-family: inherit,
    font-size: 14px,
    foreground-color: var(--text-color-secondary),
    grid-size: 12px,
    header-background-color: hsla(var(--color-white), 0),
    header-column-resize-handle-color: var(--border-separator),
    header-foreground-color: var(--text-color-secondary),
    header-height: $header-height,
    icon-size: 16px,
    input-disabled-background-color: transparent,
    input-disabled-border-color: transparent,
    input-focus-border-color: transparent,
    input-focus-box-shadow: none,
    modal-overlay-background-color: var(--bg-overlay),
    odd-row-background-color: transparent,
    range-selection-background-color-2: hsla(var(--color-white), 0.5),
    range-selection-background-color-3: hsla(var(--color-white), 0.5),
    range-selection-background-color-4: hsla(var(--color-white), 0.5),
    range-selection-background-color: hsla(var(--color-white), 0.5),
    row-height: 20px,
    row-hover-color: var(--bg-area-hovered),
    secondary-border-color: var(--border-default),
    selected-row-background-color: var(--bg-area-selected),
    subheader-background-color: transparent,
  )
);

.ag-theme-alpine-dark {
  --ag-border-color: var(--border-default);

  .ag-cell-wrapper {
    @apply w-full;
  }

  .ag-pinned-left-header {
    @apply border-0;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    @apply border-r-0;
  }

  .ag-header-cell-text {
    @apply text-small font-normal text-color-tertiary;
  }

  .ag-header {
    @apply border-0;
    @apply h-11;
  }

  .ag-row {
    @apply border-0;
    @apply text-default;
    @apply overflow-hidden;

    &.ag-row-selected {
      @apply bg-area-selected;
    }
  }

  .ag-cell {
    @apply flex;
    @apply items-center;
    @apply border-0 border-b border-default;
    @apply antialiased;
    @apply text-small;

    .ag-react-container {
      @apply w-full;
    }

    &[col-id='checkbox'],
    &[col-id='space'] {
      @apply border-0;
    }
  }

  .ag-root-wrapper,
  .ag-root-wrapper-body {
    @apply h-full;
  }

  .ag-body-viewport {
    @apply overflow-y-auto #{!important};
  }

  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    @apply antialiased;
  }

  .ag-header-label-icon {
    @apply ml-1 #{!important};
  }

  .ag-overlay .ag-react-container {
    @apply h-full;
    @apply w-full;
    @apply flex;
  }
  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
    padding: $header-height;
  }

  .ag-body-horizontal-scroll.ag-invisible-scrollbar {
    @apply hidden;
  }

  .ag-horizontal-left-spacer {
    @apply opacity-0;
  }

  .ag-checkbox {
    @apply flex items-center;
    @apply text-default;
    @apply h-5;
    @apply mr-0;
  }

  .ag-checkbox-input-wrapper {
    @apply flex items-center;
    @apply w-4;
    @apply h-4;

    &::after {
      content: ' ';
      color: transparent;
      @apply border border-input;
      @apply rounded;
      @apply w-full;
      @apply h-full;
      transition-property: border-color, opacity;
      @apply ease-in-out duration-100;
    }

    &:hover::after {
      @apply border-input-focused;
    }

    .ag-checkbox-input {
      @apply cursor-pointer;
    }
  }
  .ag-checkbox-input-wrapper.ag-checked::after,
  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    content: ' ';
    background: hsla(var(--color-accent-medium), 1)
      url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.465 4.39999L3.58632 6.52131L8.53607 1.57157' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
      no-repeat center;
    @apply rounded #{!important};
    @apply border-0;
  }
  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    background: hsla(var(--color-accent-medium), 1)
      url("data:image/svg+xml,%3Csvg width='8' height='2' viewBox='0 0 8 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 1' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
      no-repeat center;
  }

  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value).ag-selection-checkbox {
    @apply opacity-0 pointer-events-none transition duration-100;
  }
  .ag-row-hover,
  .ag-row-selected {
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value).ag-selection-checkbox {
      @apply opacity-100 pointer-events-auto;
    }
  }
  .ag-ltr .ag-selection-checkbox {
    @apply m-0;
  }

  .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-layout-auto-height .ag-center-cols-container,
  .ag-layout-print .ag-center-cols-clipper,
  .ag-layout-print .ag-center-cols-container {
    min-height: 0;
  }

  .ag-center-cols-viewport {
    min-height: unset !important;
  }
}

.szh-menu-container--theme-dark {
  .szh-menu {
    @apply p-1.5 min-w-60 rounded-xl border-0 shadow-popover bg-overlay text-color-highlight z-50 mt-2;
    &:focus {
      @apply outline-none;
    }
    &__item {
      @apply flex items-center cursor-pointer rounded-lg text-color-highlight px-2.5 py-1.5 text-default flex-1;
      &:hover {
        @apply bg-menu-hovered;
      }
      &--open {
        @apply bg-menu-hovered;
      }
      &::after {
        @apply hidden;
      }
    }
  }
}
