.root {
  &::-webkit-scrollbar {
    @apply hidden;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  scroll-snap-type: x mandatory;
}

.container {

  [role='tabpanel'] {
    scroll-snap-stop: always;
    scroll-snap-align: center;
  }
}
