.input[type="text"]:focus {
  box-shadow: none;
}

.start {
  &[data-filled="true"] {
    @apply text-input-active;
  }
  &[data-filled="false"] {
    @apply text-input-resting;
  }
}
