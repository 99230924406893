@import 'core-styles.css';

:root,
.force-dark {
  --secondary-h: 228;
  --secondary-s: 10%;
  --secondary-l: 22%;

  --surface-h: 228;
  --surface-s: 10%;
  --surface-l: 12%;

  /* Background */
  --bg-layer-00: var(--neutral-100);
  --bg-layer-01: var(--surface-h) var(--surface-s) var(--surface-l);
  --bg-layer-02: var(--neutral-140);
  --bg-layer-03: var(--neutral-160);
  --bg-layer-04: var(--neutral-180);

  --bg-inverted: var(--neutral-1000);

  --bg-outline-00: var(--neutral-160);
  --bg-outline-01: var(--neutral-180);
  --bg-outline-02: var(--neutral-220);
  --bg-outline-03: var(--neutral-240);

  --bg-interactive-primary-resting: var(--accent-500);
  --bg-interactive-primary-hovered: var(--accent-600);
  --bg-interactive-primary-pressed: var(--accent-700);

  --bg-interactive-secondary-resting: var(--secondary-500);
  --bg-interactive-secondary-hovered: var(--secondary-600);
  --bg-interactive-secondary-pressed: var(--secondary-700);

  --bg-interactive-tertiary-hovered: var(--neutral-220);
  --bg-interactive-tertiary-pressed: var(--neutral-240);

  --bg-interactive-destructive-resting: var(--alert-500);
  --bg-interactive-destructive-hovered: var(--alert-600);
  --bg-interactive-destructive-pressed: var(--alert-700);

  --bg-interactive-smart-resting: var(--smart-500);
  --bg-interactive-smart-hovered: var(--smart-600);
  --bg-interactive-smart-pressed: var(--smart-700);

  --bg-interactive-navigation-hovered: var(--neutral-180);
  --bg-interactive-navigation-pressed: var(--accent-500);

  --bg-interactive-bucket-resting: var(--neutral-140);
  --bg-interactive-bucket-hovered: var(--neutral-160);
  --bg-interactive-bucket-pressed: var(--neutral-200);

  --bg-interactive-stack-pressed: var(--neutral-180);
  --bg-interactive-stack-resting: var(--neutral-140);
  --bg-interactive-stack-hovered: var(--neutral-160);

  --bg-interactive-tab-hovered: var(--neutral-180);
  --bg-interactive-tab-pressed: var(--neutral-220);

  --bg-input-text-resting: var(--neutral-100);
  --bg-input-text-pressed: var(--neutral-80);

  --bg-input-search-resting: var(--neutral-80);
  --bg-input-search-pressed: var(--neutral-100);

  --bg-input-select-active: var(--accent-500);
  --bg-input-select-pressed: var(--accent-600);
  --bg-input-select-hovered: var(--neutral-240);
  --bg-input-select-resting: var(--neutral-240);
  --bg-input-select-disabled: var(--neutral-240);

  /* Border */

  --border-outline-00: var(--neutral-160);
  --border-outline-01: var(--neutral-180);
  --border-outline-02: var(--neutral-220);
  --border-outline-03: var(--neutral-240);

  --border-outline-accent: var(--accent-500);

  --border-outline-transparent-opacity: var(--neutral-1000);

  --border-interactive-primary-resting: var(--accent-600);
  --border-interactive-primary-hovered: var(--accent-700);
  --border-interactive-primary-pressed: var(--accent-800);

  --border-interactive-secondary-resting: var(--secondary-600);
  --border-interactive-secondary-hovered: var(--secondary-700);
  --border-interactive-secondary-pressed: var(--secondary-800);

  --border-interactive-tertiary-hovered: var(--neutral-280);
  --border-interactive-tertiary-pressed: var(--neutral-320);

  --border-interactive-destructive-resting: var(--alert-600);
  --border-interactive-destructive-hovered: var(--alert-700);
  --border-interactive-destructive-pressed: var(--alert-800);

  --border-interactive-smart-resting: var(--smart-600);
  --border-interactive-smart-hovered: var(--smart-700);
  --border-interactive-smart-pressed: var(--smart-800);

  --border-interactive-navigation-hovered: var(--neutral-240);
  --border-interactive-navigation-pressed: var(--accent-500);

  --border-interactive-bucket-resting: var(--neutral-180);
  --border-interactive-bucket-hovered: var(--neutral-200);
  --border-interactive-bucket-pressed: var(--neutral-220);

  --border-interactive-tab-hovered: var(--neutral-220);
  --border-interactive-tab-pressed: var(--neutral-240);

  --border-input-text-resting: var(--neutral-220);
  --border-input-text-hovered: var(--neutral-280);
  --border-input-text-pressed: var(--neutral-400);

  --border-input-search-resting: var(--neutral-140);
  --border-input-search-hovered: var(--neutral-180);
  --border-input-search-pressed: var(--neutral-400);

  --border-input-select-resting: var(--neutral-480);
  --border-input-select-hovered: var(--neutral-560);
  --border-input-select-active: var(--accent-600);
  --border-input-select-pressed: var(--accent-700);
  --border-input-select-disabled: var(--neutral-320);

  /* text */
  --text-color-highlight: var(--neutral-1000);
  --text-color-primary: var(--neutral-940);
  --text-color-secondary: var(--neutral-740);
  --text-color-tertiary: var(--neutral-620);
  --text-color-muted: var(--neutral-400);

  --text-color-accent: var(--accent-500);

  --text-interactive-accent-resting: var(--accent-500);
  --text-interactive-accent-hovered: var(--accent-600);
  --text-interactive-accent-active: var(--accent-700);

  --text-color-inverted: var(--neutral-80);

  --text-static-white: var(--neutral-1000);
  --text-static-black: var(--neutral-80);

  --text-interactive-primary-resting: var(--accent-label-h) var(--accent-label-s) var(--accent-label-l);

  --text-interactive-secondary-resting: var(--text-color-primary);

  --text-interactive-tertiary-resting: var(--text-color-secondary);
  --text-interactive-tertiary-hovered: var(--text-color-primary);
  --text-interactive-tertiary-active: var(--text-color-highlight);

  --text-interactive-navigation-resting: var(--text-color-secondary);
  --text-interactive-navigation-pressed: var(--text-color-accent);

  --text-interactive-bucket-resting: var(--text-color-secondary);
  --text-interactive-bucket-pressed: var(--text-color-primary);

  --text-interactive-link-resting: var(--blue-650);
  --text-interactive-link-hovered: var(--blue-750);

  --text-interactive-tab-hovered: var(--neutral-700);
  --text-interactive-tab-resting: var(--neutral-700);
  --text-interactive-tab-pressed: var(--text-color-highlight);

  --text-input-resting: var(--text-color-muted);
  --text-input-active: var(--text-color-primary);

  /* feedback */
  --color-feedback-alert: var(--alert-600);
  --color-feedback-warning: var(--warning-500);
  --color-feedback-success: var(--success-600);
  --color-feedback-smart: var(--smart-700);
  --color-feedback-inverted: var(--neutral-1000);
  --color-feedback-accent: var(--accent-700);
  --color-feedback-neutral: var(--neutral-620);

  --color-skeleton: var(--neutral-480);
  --color-skeleton-light: var(--neutral-1000);
  --color-skeleton-dark: var(--neutral-480);

  /* objects */
  --color-object-developer: var(--blue-600);
  --color-object-customer: var(--green-600);
  --color-object-mention: var(--orange-600);
  --color-object-engagement: var(--pink-500);
  --color-object-opportunity: var(--lime-500);
  --color-object-conversation: var(--orange-500);
  --color-object-ticket: var(--color-feedback-neutral);
  --color-object-issue: var(--blue-500);
  --color-object-sub-feature: var(--sky-500);
  --color-object-database: var(--orange-500);
  --color-object-linkable: var(--yellow-500);
  --color-object-runnable: var(--lime-500);
  --color-object-api-cluster: var(--yellow-500);
  --color-object-api: var(--green-500);
  --color-object-product: var(--violet-500);
  --color-object-enhancement: var(--green-500);
  --color-object-feature: var(--sky-500);
  --color-object-capability: var(--blue-500);
  --color-object-sprint-board: var(--lime-500);
  --color-object-incident: var(--red-500);
  --color-transparent-opacity: var(--neutral-80);

  --color-transparent-opacity: var(--neutral-1000);

  --color-decorative-red: var(--red-700);
  --color-decorative-orange: var(--orange-700);
  --color-decorative-yellow: var(--yellow-850);
  --color-decorative-lime: var(--lime-900);
  --color-decorative-green: var(--green-900);
  --color-decorative-sky: var(--sky-800);
  --color-decorative-blue: var(--blue-800);
  --color-decorative-violet: var(--violet-800);
  --color-decorative-pink: var(--pink-850);

  /* smart loading bar */
  /* TODO @pranav - change these hardcoded values when we have correct token values */
  --bg-smart-loading: 250 80% 64%;
  --gradient-smart-loading: linear-gradient(
    to right,
    hsla(249, 84%, 93%, 0),
    hsla(249, 84%, 93%, 1),
    hsla(249, 84%, 93%, 0)
  );
  --gradient-smart-loading-reverse: linear-gradient(
    to right,
    hsla(249, 84%, 93%, 1),
    hsla(250, 80%, 64%, 1) 15%,
    90%,
    hsla(249, 84%, 93%, 1) 100%
  );

  /* shadows */
  --shadow-depth-04: 0 2px 8px 0 hsla(var(--neutral-100) / 0.8), 0 16px 24px 0 hsla(var(--neutral-100) / 0.8);
  --shadow-depth-03: 0 0 0 1px hsla(var(--neutral-100) / 0.8), 0 2px 16px 0 hsla(var(--neutral-100) / 0.8);
  --shadow-depth-02: 2px 2px 12px 0 hsla(var(--neutral-100) / 0.8), 0 0 6px 0 hsla(var(--neutral-100) / 0.8);
  --shadow-depth-01: 0 0 1px 0 hsla(var(--neutral-100) / 0.25), 0 2px 1px 0 hsla(var(--neutral-100) / 0.15);
  --shadow-popover-arrow: 0px 2px 0px hsla(var(--border-outline-01) / 1);
  --shadow-interactive-focused: 0px 0px 0px 4px hsl(var(--neutral-1000) / 0.1),
    0px 0px 0px 1px hsl(var(--neutral-1000) / 0.4);
  --shadow-interactive-lifted: 0 2px 8px 0 hsl(var(--neutral-100) / 0.08);
  --shadow-interactive-pressed: inset 0 2px 0 0 hsl(var(--neutral-120) / 0.05);
  --shadow-interactive-resting: 0 1px 2px 0 hsl(var(--neutral-100) / 0.05);
  --shadow-depth-inverted: -0.01px -0.12px 3px 0px rgba(0, 0, 0, 0.07), -0.02px -0.6px 7px 0px rgba(0, 0, 0, 0.08),
    -0.06px -1.4px 14px 0px rgba(0, 0, 0, 0.09), -0.11px -2.5px 27px 0px rgba(0, 0, 0, 0.09),
    -0.18px -4.5px 50px 0px rgba(0, 0, 0, 0.1), -0.28px -7px 87px 0px rgba(0, 0, 0, 0.12);
  --shadow-effect-2d: 0px -1px 0px 0px hsl(var(--neutral-1000) / 0.05) inset;
}
