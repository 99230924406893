@use './animations.module.scss' as animations;

@include animations.component-appear-animations;

.root {
  &[data-state='open'] {
    animation: slideDown 300ms;
  }
  &[data-state='closed'] {
    animation: slideUp 300ms;
  }
}
