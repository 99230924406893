@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Chrome, Safari, Edge, Opera */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  body {
    h1 {
      font-size: var(--fontSize-h1);
      font-weight: var(--fontWeight-h1-medium);
      line-height: var(--lineHeight-h1);
    }

    h2 {
      font-size: var(--fontSize-h2);
      font-weight: var(--fontWeight-h2-medium);
      line-height: var(--lineHeight-h2);
    }

    h3 {
      font-size: var(--fontSize-h3);
      font-weight: var(--fontWeight-h3-medium);
      line-height: var(--lineHeight-h3);
    }

    h4 {
      font-size: var(--fontSize-h4);
      font-weight: var(--fontWeight-h4-medium);
      line-height: var(--lineHeight-h4);
    }

    h5 {
      font-size: var(--fontSize-h5);
      font-weight: var(--fontWeight-h5-medium);
      line-height: var(--lineHeight-h5);
    }

    h6 {
      font-size: var(--fontSize-h6);
      font-weight: var(--fontWeight-h6-medium);
      line-height: var(--lineHeight-h6);
    }
  }
}
